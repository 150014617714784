import {PaginationModel} from "../../../sharedmodels/filter/PaginationModel";
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {Loading} from "../../loading/loading";
import classNames from "classnames";

export type FilterNotationProps = {
    paginationModel: PaginationModel;
    filteredRecords: number;
    customTotalRecords?: number;
    xOfYisLoading?: boolean;
    hidePaginatedCount?: boolean;
    additionalContainerClasses?: string;
}
const FilterNotation = ({
    paginationModel, 
    filteredRecords, 
    customTotalRecords, 
    xOfYisLoading,
    hidePaginatedCount = false,
    additionalContainerClasses,
} : FilterNotationProps) => {

    const recordsTotal = customTotalRecords === 0 || customTotalRecords === undefined ? paginationModel.recordCount : customTotalRecords;
    const startItem = ((paginationModel.page - 1) * paginationModel.pageLength) + 1;
    const totalPageNumber = Math.ceil(filteredRecords / paginationModel.pageLength);
    const endItem = totalPageNumber === paginationModel.page ? filteredRecords : (paginationModel.page * paginationModel.pageLength);

    const showPaginationCountOutOfFiltered = !hidePaginatedCount && recordsTotal > 0;

    return (
        <div className={additionalContainerClasses}>
            <div className={"flex-container-row"}>
                {Localizer.global_filtrerede()}
                {xOfYisLoading ? <Loading isLoading={xOfYisLoading} spinnerSize={"p-size"} spinnerClasses={"margin-left-xs margin-right-xs"} timeout={2000}/> : <>{filteredRecords}</>}
                {Localizer.evalueringPage_udAf()}
                {xOfYisLoading ? <Loading isLoading={xOfYisLoading} spinnerSize={"p-size"} spinnerClasses={"margin-left-xs margin-right-xs"} timeout={2000}/> : <>{recordsTotal}</>}
            </div>
            {showPaginationCountOutOfFiltered &&
                <div className={"flex-container-row"}>
                    {Localizer.global_filtrerede()} {startItem}-{endItem} {Localizer.evalueringPage_udAf()}
                    {xOfYisLoading
                        ? <Loading isLoading={xOfYisLoading} spinnerSize={"p-size"}
                                   spinnerClasses={"margin-left-xs margin-right-xs"}
                                   timeout={2000}/>
                        : <>{filteredRecords}</>}
                </div>
            }
        </div>
    )
}

export default FilterNotation;
