import iconSpriteSvg from "content/icons/sprite.symbol.svg";
import './emblaIcon.scss';
import { EmblaIconsType } from "core/emblaIcons";
import classNames from "classnames";

export type EmblaIconProps = {
    iconName: EmblaIconsType;
    additionalClasses?: string;
    spriteSvgClasses?: string;
    wrapInSpan?: boolean;
    size?: "small" | "medium" | "large" | "huge";
};

export function EmblaIcon({
    iconName,
    additionalClasses,
    spriteSvgClasses,
    wrapInSpan = true,
    size //Note: Dont give this a default value, because there is defined custom css sizes for different use-cases (e.g. button)
}: EmblaIconProps) {

    const renderEmblaIcon = () => {
        if (iconName) {
            return (
                <svg className={classNames("embla-icon", additionalClasses, size)}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg">
                        <use className={classNames(spriteSvgClasses)} href={iconSpriteSvg + "#" + iconName} />
                </svg>)
        }
        return;
    };

    return (
        <>
            {wrapInSpan ? <span>{renderEmblaIcon()}</span> : renderEmblaIcon() }
        </>
    );
}
