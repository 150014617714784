import useScreenResolution from "../../core/hooks/useScreenResolution";
import React, {useState} from "react";
import {Localizer} from "../../infrastructure/localization/localizer";
import {Tab, Tabs} from "../../core/components/Tabs/Tabs";
import {ForloebHeader} from "../../core/components/headerCards/forloebHeader";
import {
    SpecialespecifikkeFilerOverview
} from "../forloeb/forloebOverviews/specialespecifikkeFilerOverview";
import UddannelsesstedFiles from "./components/uddannelsesstedFiles";
import ForloebSpecificPageWrapper from "core/componentsPage/forloeb/forloebSpecificPageWrapper";


export const FilerPage = () => {
    const {isMobile} = useScreenResolution();
    const [activeTab, setActiveTab] = useState<string>();

    const setTabAndHistory = (id: string) => {
        setActiveTab(id);
    }

    const tabs: Tab[] = [
        {
            title: Localizer.forloebpage_specialespecifikkeFiler(),
            id: Localizer.forloebpage_specialespecifikkeFiler(),
            defaultActive: activeTab === Localizer.forloebpage_specialespecifikkeFiler(),
            content: <SpecialespecifikkeFilerOverview/>
        }, {
            title: Localizer.uddannelsesplan(),
            id: Localizer.uddannelsesplan(),
            defaultActive: activeTab === Localizer.uddannelsesplan(),
            content: <UddannelsesstedFiles />
        }
    ];

    return (
        <>
            <ForloebSpecificPageWrapper>
                <ForloebHeader hideSeUddannelseAndForloebDetails={true}/>
                {!isMobile ?
                    <div className="row top-level-row">
                        <div className="col-md-6 col-12">
                            <SpecialespecifikkeFilerOverview/>
                        </div>
                        <div className="col-md-6 col-12">
                            <UddannelsesstedFiles />
                        </div>
                    </div>
                    :
                    <div className="top-level-row">
                        <Tabs tabs={tabs} tabClickedCallback={x => setTabAndHistory(x.id)}/>
                    </div>
                }
            </ForloebSpecificPageWrapper>
        </>
    );
}
