import React, {useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {Localizer} from '../../../infrastructure/localization/localizer';
import {Loading} from '../loading/loading';
import {EmblaIcon} from '../emblaIcon/emblaIcon';
import {forloebReducer, ForloebSliceState, setCurrentForloeb} from 'core/forloebSlice';
import {setSlideinState, SlideInStateEnum} from "../slideIn/slideInSlice";
import {Tab, Tabs} from "../Tabs/Tabs";
import {RoutePaths} from "../../../infrastructure/routes";
import {useHistory, useRouteMatch} from "react-router-dom";
import {closeModal} from "../slideIn/slideIn";
import {CrudAnsoegningInputModel} from "../../../pages/sendStpsAnsoegning/ansoegning/crudAnsoegningInputModel";
import {toDropdownOptions} from "../../sharedmodels/forSelectModel";
import {ForloebModel} from "../../sharedmodels/forloeb/forloebModel";
import {LogbogAnsoegningResponseModel} from "../../../services/api/logbogAnsoegning/logbogAnsoegningResponseModel";
import {FileMetadataList} from "../fileMetadata/fileMetadataList";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import {NotatType} from "../../sharedmodels/notat/notatType";
import {BasicNotatModel} from "../../sharedmodels/notat/basicNotatModel";
import {UddannelseTypeEnum} from "../../sharedmodels/stilling/uddannelseTypeEnum";
import {ObligatoriskDokumentation} from "../obligatoriskDokumentation/obligatoriskDokumentationTable";
import {ForloebCardInfo} from "../../componentsPage/forloeb/forloebCardInfo";
import {FileMetadata} from "../../sharedmodels/fileMetaData/fileMetaData";
import {DocumentationList} from "../obligatoriskDokumentation/documentationList";
import {BrugerMaalbeskrivelseResponseModel} from 'services/api/stamdataMaalbeskrivelse/brugerMaalbeskrivelseResponseModel';
import {ForloebSimpleInfoModel} from "../../sharedmodels/forloeb/forloebSimpleInfoModel";
import {ForloebSimpleCardInfo} from "../../componentsPage/forloeb/forloebSimpleCardInfo";
import useUser from "../../hooks/useUser";
import {Dropdown, DropdownOption} from "../dropdown/dropdown";
import { NotificationModule } from 'ditmer-embla';
import { GetDefaultSelectedForloebViaForloebList } from 'pages/forloeb/forloebApi';
import useLogbogApi from 'core/hooks/useLogbogApi';
import useStamdataApi from 'core/hooks/useStamdataApi';

type UddannelseOverviewProps = {
    forloeb: ForloebModel
    modalId: string;
    initialActiveTabId?: string;
}

const resolveAnsoegningsViewTitle = (uddannelsesType?: UddannelseTypeEnum) => {
    switch (uddannelsesType) {
        case UddannelseTypeEnum.Introduktion:
            return Localizer.global_introduktionsstilling();
        case UddannelseTypeEnum.KBU:
            return Localizer.global_selvstaendigtVirke();
        case UddannelseTypeEnum.Hoveduddannelse:
            return Localizer.global_speciallaegeanerkendelse();
        default:
            return "";
    }
}
export const forloebDetaljerTabId = "forloebDetaljerTabId";
export const forloebshistorikTabId = "forloebshistorikTabId";
export const filerTabId = "filerTabId";
export const ansoegningTabId = "ansoegningTabId";

export function UddannelseOverview({initialActiveTabId="", ...props}: UddannelseOverviewProps) {
    const routeMatch = useRouteMatch();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const {
        forloebApi,
        logbogAnsoegningApi,
        notatApi,
        logbogFileApi,
    } = useLogbogApi();

    const { stamdataMaalbeskrivelseApi } = useStamdataApi();

    const [brugerMaalbeskrivelse, setBrugerMaalbeskrivelse] = useState<BrugerMaalbeskrivelseResponseModel>();
    const [isLoadingSlideIn, setIsLoadingSlideIn] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(true);

    const [ansoegning, setAnsoegning] = useState<LogbogAnsoegningResponseModel>();
    const [selectedUddannelseId, setSelectedUddannelseId] = useState<string>(props.forloeb.uddannelseId);

    const [forloebListFiltered, setforloebListFiltered] = useState<ForloebModel[]>([]);
    const [uddannelseNotat, setUddannelseNotat] = useState<BasicNotatModel | null>();

    const [documentation, setDocumentation] = useState<FileMetadata[]>([]);
    const [eftersendtDocumentation, setEftersendtDocumentation] = useState<FileMetadata[]>([]);

    const [allForloebOnUserSimpleInfo, setAllForloebOnUserSimpleInfo] = useState<ForloebSimpleInfoModel[]>([]);

    const { currentUser } = useUser();
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const [selectedBrugerMaalbeskrivelsesId, setSelectedBrugerMaalbeskrivelsesId] = useState<string>(forloebSliceState.forloebState.brugerMaalbeskrivelseId);

    const [brugerMaalbeskrivelseSelectOptions, setBrugerMaalbeskrivelseSelectOptions] = useState<DropdownOption<string>[]>([]);
    const [activeTabId, setActiveTabId] = useState<string>(initialActiveTabId);

    const routeUserId = (routeMatch.params as { id: string }).id;

    const shouldHideDropdown = currentUser.IsKonsulentForLaegeligVidereuddannelse() || currentUser.IsLedelse();
    const shouldHideDetaljerTab = currentUser.IsKonsulentForLaegeligVidereuddannelse() || currentUser.IsLedelse();
    const shouldHideFilerTab = !currentUser.IsAdminOrVus() && !currentUser.IsLaege()
    const shouldHideAnsoegningsTab = currentUser.IsKonsulentForLaegeligVidereuddannelse() || currentUser.IsSekretaer() || currentUser.IsLedelse();

    useEffect(() => {
        const getBrugerMaalbeskrivelse = async () => {
            const currentBrugerMaalbeskrivelse = await stamdataMaalbeskrivelseApi.getBrugerMaalbeskrivelse(selectedBrugerMaalbeskrivelsesId);
            setBrugerMaalbeskrivelse(currentBrugerMaalbeskrivelse);

            const brugerMaalbeskrivelser = await stamdataMaalbeskrivelseApi.getBrugerMaalbeskrivelseSelectByBruger(routeUserId, currentBrugerMaalbeskrivelse.id);
            setBrugerMaalbeskrivelseSelectOptions(toDropdownOptions(brugerMaalbeskrivelser));

            setIsLoadingSlideIn(false);

            const forloeb = await forloebApi.getForloebByBrugerMaalbeskrivelse(currentBrugerMaalbeskrivelse.id);
            const filteredForloebList = forloeb.filter(list => list.brugerMaalbeskrivelseId === currentBrugerMaalbeskrivelse.id);
            setforloebListFiltered(filteredForloebList);

            const forloebCount = filteredForloebList.length;
            const currentForloebIsInForloebList = filteredForloebList.map(x => x.id).includes(props.forloeb.id);

            if (!currentForloebIsInForloebList && forloebCount > 0) {
                const defaultSelectedForloeb = GetDefaultSelectedForloebViaForloebList(filteredForloebList);
                dispatch(setCurrentForloeb(defaultSelectedForloeb.ToState()));
            }

            setSelectedUddannelseId(currentBrugerMaalbeskrivelse.uddannelseId);

            if (!shouldHideAnsoegningsTab){
                const existingAnsoegning = await logbogAnsoegningApi.getAnsoegningOnBruger(routeUserId, currentBrugerMaalbeskrivelse.id);
                if (existingAnsoegning) {
                    setAnsoegning(existingAnsoegning)

                    const notater = await notatApi.getAnsoegningsnotaterOnUddannelse(routeUserId, currentBrugerMaalbeskrivelse.id, existingAnsoegning.id);
                    const documents = notater?.filter(y => !y.eftersendt).selectMany(x => x.fileMetadatas);
                    const eftersendt = notater?.filter(y => y.eftersendt).selectMany(x => x.fileMetadatas);

                    setDocumentation(documents);
                    setEftersendtDocumentation(eftersendt);
                }
            }

            if (!shouldHideFilerTab) {
                const uddannelsesNotat = await notatApi.getNotatOnContext(NotatType.Uddannelse, routeUserId, currentBrugerMaalbeskrivelse.uddannelseId);
                setUddannelseNotat(uddannelsesNotat)
            }

            const allForloebOnUserSimpleInfo = await forloebApi.getAllForloebSimpleByBruger(routeUserId);
            setAllForloebOnUserSimpleInfo(allForloebOnUserSimpleInfo);
        }

        setIsLoadingData(true);
        getBrugerMaalbeskrivelse().then(() => setIsLoadingData(false));
    }, [dispatch, forloebApi, logbogAnsoegningApi, notatApi, props.forloeb.id, routeUserId, selectedBrugerMaalbeskrivelsesId, shouldHideAnsoegningsTab, shouldHideFilerTab, stamdataMaalbeskrivelseApi])

    const onSelectedForloeb = (selectedModel: ForloebModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}))
        dispatch(setCurrentForloeb(selectedModel.ToState()));
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}))
    }

    const setUddannelseNotatState = async (uddannelsesId: string) => {
        if (shouldHideFilerTab) return;
        const uddannelseNotat = await notatApi.getNotatOnContext(NotatType.Uddannelse, routeUserId, uddannelsesId);
        setUddannelseNotat(uddannelseNotat)

        handleSuccessNotifications(uddannelseNotat);
    }

    const handleSuccessNotifications = (newBasicNotatModel?: BasicNotatModel | null) => {
        if (!uddannelseNotat || !newBasicNotatModel) return;

        const hasRemovedAFile = newBasicNotatModel.fileMetadatas.length < uddannelseNotat.fileMetadatas.length;
        const notificationMessage = hasRemovedAFile ? Localizer.forloebEdit_FileRemovedSuccess() : Localizer.forloebEdit_FileUploadedSuccess();
        NotificationModule.showSuccess(notificationMessage, "");
    }

    const detaljerView = (
        <div className='mt-3'>
            {forloebListFiltered.map((forloeb, index) => {
                const todayDate = new Date();
                return (
                    <div className="forloeb-uddannelse-list" key={forloeb.id}>
                        <div
                            className={`card shadow-none header-card forloeb-header flex-space ${forloeb.erFravaer ? "color-fravaer-forloeb" : forloeb.irrelevant ? "color-irrelevant-forloeb" : "" } ${forloeb.startDate < todayDate && forloeb.endDate > todayDate ? "active-forloeb" : ""} ${forloeb.id == props.forloeb.id ? "active" : ""}`}
                            data-toggle="modal">
                            <div className={"flex-space padding-right-m"}>
                                <div>
                                    <ForloebCardInfo forloeb={forloeb}/>
                                </div>
                                {forloeb.erFravaer
                                ? <></>
                                : <div className="rounded-circle forloeb-uddannelse-circle clickable-row"
                                       onClick={() => onSelectedForloeb(forloeb)}>
                                        <EmblaIcon iconName="arrow-right"/>
                                  </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )

    const forloebUdenAdgang = (
        <div className='mt-3'>
            <div className="col-sm-12">
                <div className="alert alert-info left-right-minus-spacing" role="alert">
                    {Localizer.forloebpage_KunSeAdgangTilForloeb()}
                </div>
            </div>
            {allForloebOnUserSimpleInfo.map((forloeb, index) => {
                const todayDate = new Date();
                return (
                    <div className="forloeb-uddannelse-list" key={forloeb.id} >
                        <div
                            className={`card shadow-none header-card forloeb-header flex-space ${forloeb.erFravaer ? "color-fravaer-forloeb" : forloeb.irrelevant ? "color-irrelevant-forloeb" : "" }  ${forloeb.startDate < todayDate && forloeb.endDate > todayDate ? "active-forloeb" : ""} ${forloeb.id == props.forloeb.id ? "active" : ""}`}
                            data-toggle="modal">
                            <div className={"flex-space padding-right-m"}>
                                <div>
                                    <ForloebSimpleCardInfo forloeb={forloeb}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )

    const filerView = (
        <>
            <h5 className="margin-bottom-m">
                {Localizer.uddannelseOverview_ansaettelseOgUddannelsesaftaler()}
            </h5>
            {forloebListFiltered.length > 0 &&
            <FileMetadataList
                fileMetadatas={uddannelseNotat?.fileMetadatas ?? []}
                showDeleteButton={currentUser.IsAdminOrVus() || currentUser.IsLaege()}
                notatInfo={{
                    contextId: forloebListFiltered[0].uddannelseId,
                    notatType: NotatType.Uddannelse,
                    userId: routeUserId,
                    isVisibleToEveryone: false
                }}
                notat={uddannelseNotat ?? undefined}
                withUploader={currentUser.IsAdminOrVus() || currentUser.IsLaege()}
                fileLocation={FileLocationEnum.Logbog}
                filesUpdatedCallback={() => setUddannelseNotatState(selectedUddannelseId)}
            />
            }
        </>
    )

    const ansoegningView = (
        <>
            <div className="aftale-file-picker margin-top-l">
                <h4 className={"padding-left-m"}>{Localizer.global_dokumentation()}</h4>
                <ObligatoriskDokumentation uddannelseId={selectedUddannelseId}
                                           brugerMaalbeskrivelseId={brugerMaalbeskrivelse?.id}
                                           forloebId={forloebSliceState.forloebState.id}
                                           ansoegningId={ansoegning?.id}
                                           userId={routeUserId}
                                           tableId={"dokumentationTableId"}
                                           simpleVersion={false}
                                           responsive={false}
                                           uddannelsesType={brugerMaalbeskrivelse?.uddannelseType}
                />
            </div>

            {(brugerMaalbeskrivelse?.uddannelseType !== UddannelseTypeEnum.Introduktion && (currentUser.IsAdminOrVus() || currentUser.UserId === routeUserId)) &&
                <div className={"flex-row-center margin-bottom-l"}>
                    <button onClick={() => onSelvstaendigVirkeViewButtonClick()}
                            className={`btn btn-default`}>
                        {Localizer.uddannelseOverview_forberedDokumentationTilSTPS()}
                    </button>
                    <a className={"quickguide-icon-uddannelseoverview margin-left-s margin-top-xxs"} title={Localizer.uddannelseOverview_quickguideLinkTooltip()} onClick={() => downloadQuickguide(brugerMaalbeskrivelse?.uddannelseType)}><EmblaIcon iconName={"question"}/></a>
                </div>
            }
            {documentation.length > 0 &&
                <div className={"padding-left-m margin-top-l"}>
                    <DocumentationList tableTitle={Localizer.uddannelseOverview_documentationSentToSTPS() + (ansoegning?.dokumentationAfsendtDen ? " - "+new Date(ansoegning.dokumentationAfsendtDen).dateWithTimeFormat() : "")}
                                       documentation={documentation}
                                       hideDate={true}/>
                </div>
            }
            {eftersendtDocumentation.length > 0 &&
                <div className={"padding-left-m margin-top-l margin-bottom-l"}>
                    <DocumentationList tableTitle={Localizer.uddannelseOverview_documentationSentLaterToSTPS()}
                                       documentation={eftersendtDocumentation}
                                       hideCreatedBy={true}
                                       showTimeInDatetime={true}
                                       />
                </div>
            }
        </>
    )

    const downloadQuickguide = async (uddannelseType? : UddannelseTypeEnum) => {
        if(uddannelseType != undefined){
            await logbogFileApi.downloadQuickguideFile(uddannelseType);
        }
    }

    const onSelvstaendigVirkeViewButtonClick = async () => {
        closeModal(props.modalId);

        if (ansoegning) {
            history.push(RoutePaths.SendStpsDocumentationPage({uddannelseslaege: routeUserId, ansoegning: ansoegning.id}).url);
        } else {
            const ansoegningInputModel = new CrudAnsoegningInputModel(routeUserId, brugerMaalbeskrivelse!.id);
            const ansoegningId = await logbogAnsoegningApi.createAnsoegning(ansoegningInputModel);
            history.push(RoutePaths.SendStpsDocumentationPage({uddannelseslaege: routeUserId, ansoegning: ansoegningId}).url);
        }
    }

    const tabs: Tab[] = [
        {
            title: Localizer.global_forloeb(),
            id: forloebDetaljerTabId,
            content: (detaljerView),
            defaultActive: activeTabId === forloebDetaljerTabId,
            hide: shouldHideDetaljerTab,
        },
        {
            title: Localizer.global_forloebshistorik(),
            id: forloebshistorikTabId,
            content: (forloebUdenAdgang),
            defaultActive: activeTabId === forloebshistorikTabId,
        },
        {
            title: Localizer.uddannelseOverview_ansaettelseOgUddannelsesaftaler(),
            id: filerTabId,
            content: (filerView),
            defaultActive: activeTabId === filerTabId,
            hide: shouldHideFilerTab,
        },
        {
            title: resolveAnsoegningsViewTitle(brugerMaalbeskrivelse?.uddannelseType),
            id: ansoegningTabId,
            content: (ansoegningView),
            defaultActive: activeTabId === ansoegningTabId,
            hide: shouldHideAnsoegningsTab
        }
    ];

    const changeUddannelse = (selectedId?: string) => {
        if (!selectedId) return;
        setSelectedBrugerMaalbeskrivelsesId(selectedId);
    }

    return (
        <Loading isLoading={isLoadingSlideIn} text={Localizer.global_getUddannelser()}>
            <div className="row">
                <div className="col-sm-12">
                    {!shouldHideDropdown &&
                        <div className="margin-bottom-m">
                            <Dropdown
                                placeholder={Localizer.vaelgUddannelse()}
                                options={brugerMaalbeskrivelseSelectOptions}
                                onChange={(newVal) => changeUddannelse(newVal?.value)}
                                value={brugerMaalbeskrivelseSelectOptions.filter(x => selectedBrugerMaalbeskrivelsesId === x.value)}
                                isDisabled={isLoadingData}
                            />
                        </div>
                    }

                    {tabs &&
                        <Loading isLoading={isLoadingData} text={Localizer.global_getUddannelser()}>
                            <Tabs key={brugerMaalbeskrivelse?.id}
                                  tabs={tabs}
                                  setActiveCallback={(id: string) =>  setActiveTabId(id)}
                            />
                        </Loading>
                    }
                </div>
            </div>
        </Loading>
    )
}
