import './user-profile.scss';
import {IsLaegeToColorClass} from "../../../infrastructure/enums/userRole";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {UserProfileAvatarModel} from "./userProfileAvatarModel";
import {RolleTitelEnum, UserRoleTitleToString} from "../../../infrastructure/enums/rolleTitelEnum";
import { AvatarBadge } from './avatarBadge';
import { ConfirmButton } from '../button/confirmButton';
import { EmblaIcons } from 'core/emblaIcons';
import CounterCircle from '../notifications/counterCircle/counterCircle';
import React, { useState } from 'react';
import {ReactFileUploaderFile} from "../fileUpload/reactFileUploader";
import classNames from "classnames";

export enum UserProfileAvatarSizeEnum {
    large,
    medium,
    small,
    extraSmall,
    extraExstraSmall
}

export enum UserProfileLayoutEnum {
    AsHeaderBelowAvatar,
    AsTextOnRightSide
}

export type UserProfileActions = {
    removeUserAction?: (userId: string) => void;
}

export type IUserProfileAvatarProps = UserProfileActions & {
    userId?: string;
    userProfileAvatarModel?: UserProfileAvatarModel;
    userProfileLayoutEnum?: UserProfileLayoutEnum;
    size?: UserProfileAvatarSizeEnum;
    subtle?: boolean;
    forloebAccessDate?: string;
    vejledertilknytningDates?: string[];
    isImpersonating?: boolean;
    userRoleTitles?: RolleTitelEnum[];
    showRedCircle?: boolean;
    showFavoriteIcon?: boolean;
    isFavorite?: boolean;
    isCurrentUser?: boolean;
    favoriserCallback?: (userId: string, isFavorite: boolean) => void;
    fileUploadedCallback?: (file: ReactFileUploaderFile) => void;
    deleteImageCallback?: () => void;
}

const userProfileAvatarWrapperClassName = "user-profile-avatar-wrapper";

export const UserProfileAvatar = ({
    showRedCircle = false,
    showFavoriteIcon = false,
    favoriserCallback,
    fileUploadedCallback,
    deleteImageCallback,
    isCurrentUser = false,
    ...props
}: IUserProfileAvatarProps) => {
    const [isFavoriteState, setIsFavoriteState] = useState(props.isFavorite);

    const getUserAvatar = () => {
        return (
            <>
                <AvatarBadge
                    profileImageFileMetadataId={props.userProfileAvatarModel?.ProfileImageId}
                    profileImageFileData={props.userProfileAvatarModel?.ProfileImageFileData}
                    initials={props.userProfileAvatarModel?.Initials}
                    size={props.size}
                    isCurrentUser={isCurrentUser}
                    colorClassName={classNames(props.subtle && "user-avatar-subtle", IsLaegeToColorClass(props.userProfileAvatarModel?.IsLaege))}
                    isImpersonating={props.isImpersonating}
                    fileUploadedCallback={fileUploadedCallback}
                    deleteImageCallback={deleteImageCallback}
                />
                {showRedCircle && <CounterCircle positionAbsolute size="extra-small" hideIfZero={false}/>}
            </>
        );
    }

    const handleFavorite = () => {
        if (!favoriserCallback || !props.userId) return;

        setIsFavoriteState(!isFavoriteState)
        favoriserCallback(props.userId, !isFavoriteState);
    }

    return (
        <>
            {props.userProfileLayoutEnum === undefined &&
            <div className={"flex-align-c"}>
                <div className={userProfileAvatarWrapperClassName}>
                    {getUserAvatar()}
                </div>
            </div>
            }

            {props.userProfileLayoutEnum === UserProfileLayoutEnum.AsHeaderBelowAvatar &&
            <>
                <div className={`${userProfileAvatarWrapperClassName} margin-top-m margin-bottom-s flex-row-center`}>
                    {getUserAvatar()}
                </div>

                <h4 className="flex-container justify-content-center margin-bottom-m">
                    {props.userProfileAvatarModel?.Name}
                </h4>
            </>
            }

            {props.userProfileLayoutEnum === UserProfileLayoutEnum.AsTextOnRightSide &&
            <div className={"flex-align-c"}>
                <div className={userProfileAvatarWrapperClassName}>
                    {getUserAvatar()}
                </div>

                <div className="user-profile-name">
                    <div className={`${props.subtle ? "subtle" : ""} ${props.userRoleTitles ? "flex-container-row" : ""}`}>
                        {props.userProfileAvatarModel?.Name}
                        {props.userRoleTitles &&
                            <div className={"margin-left-xs"}>
                                    ({props.userRoleTitles.map(r => UserRoleTitleToString(r)).join(", ")})
                            </div>}
                    </div>
                    {props.forloebAccessDate &&
                    <>
                        <div>
                            <EmblaIcon additionalClasses={"subtle margin-right-xs"} iconName="calendar"/>
                            {props.forloebAccessDate}
                        </div>
                    </>
                    }
                </div>

                <div className={"d-flex align-items-center ml-auto"}>
                    {!isFavoriteState && showFavoriteIcon &&
                        <div className="mr-2" onClick={() => handleFavorite()} role="button">
                            <EmblaIcon iconName={EmblaIcons.Star} />
                        </div>
                    }

                    {isFavoriteState && showFavoriteIcon &&
                        <div className="mr-2" onClick={() => handleFavorite()} role="button">
                            <EmblaIcon iconName={EmblaIcons.StarFilled} />
                        </div>
                    }

                    {props.removeUserAction &&
                        <ConfirmButton
                            key={EmblaIcons.Delete + props.userId}
                            icon={EmblaIcons.Delete}
                            asButton={false}
                            confirmCallback={() => {
                                if(props.userId) {
                                    props.removeUserAction!(props.userId)
                                }
                            }}
                            additionalClasses={`btn btn-icon ${props.userProfileAvatarModel?.Email ? "padding-right-xl" : ""}`}
                        />
                    }

                    {props.userProfileAvatarModel?.Email &&
                        <a href={`mailto:${props.userProfileAvatarModel?.Email}`} >
                            <EmblaIcon iconName={EmblaIcons.Email}/>
                        </a>
                    }
                </div>

            </div>
            }
        </>
    );
}
