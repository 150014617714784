import {Localizer} from "infrastructure/localization/localizer";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {UserProfileModel} from "./userProfileModel";
import {AsyncInput} from "../asyncInput/asyncInput";
import {AccountService} from "../../../services/account/accountService";
import {NotificationModule} from "ditmer-embla";
import {Spinner} from "../spinner/spinner";
import React, {useEffect, useState} from "react";
import {SlideIn} from "../slideIn/slideIn";
import {TextEditor} from "../textEditor/textEditor";
import {ModalSize} from "../slideIn/modalSize";
import {ModalType} from "../slideIn/modalType";
import {ApiResponse} from "../../../services/api/apiResponse";
import {Caption} from "../caption/caption";
import {toDropdownOptions} from "../../sharedmodels/forSelectModel";
import {GetKbuRundeEnumTranslation} from "../../sharedmodels/user/kbuRundeEnum";
import {Table} from "../table/table";
import {DataTableHeader} from "../table/DataTableHeader";
import {UserRoleTitleToString} from "../../../infrastructure/enums/rolleTitelEnum";
import {UserRoleToString} from "../../../infrastructure/enums/userRole";
import {Dropdown, DropdownOption} from "../dropdown/dropdown";
import {AsyncButton} from "../button/asyncButton";
import useUser from "core/hooks/useUser";
import useStamdataApi from "core/hooks/useStamdataApi";
import useLogbogApi from "core/hooks/useLogbogApi";
import Title from "../titels/title";
import CalendarTimeDisplay from "../timedisplayers/Calendar";
import useScreenResolution from "core/hooks/useScreenResolution";
import './profileImage.scss'
import {UserProfileImage} from "./userProfileImage";

export const UserProfile = ({...props}: { user: UserProfileModel }) => {
    const [user, setUser] = useState<UserProfileModel>(props.user)

    const { isMobile } = useScreenResolution();

    const { logbogExportApi } = useLogbogApi();
    const { stamdataUserApi, stamdataUserAccountApi, stamdataPostnummerApi } = useStamdataApi();
    const { currentUser, updateCurrentUserInitials } = useUser();

    const isCurrentUser = currentUser.UserId === props.user.userId;
    const isAdminOrVusOrCurrentUser = currentUser.IsAdminOrVus() || isCurrentUser;

    const samtykkeErklaeringModal = "erklaering-modal";
    const tilbagekaldSamtykkeModal = "tilbagekald-modal";
    const [emailSent, setEmailSent] = useState<boolean | undefined>();
    const [asyncProcessing, setAsyncProcessing] = useState<boolean>(false)
    const [postnummerSelectOptions, setpostnummerSelectOptions] = useState<DropdownOption<string>[]>([]);
    const [selectedPostnummer, setSelectedPostnummer] = useState<string>();
    const [gdprLastFetchAt, setGdprLastFetchAt] = useState<Date | undefined>(props.user.gdprLastFetchAt);

    useEffect(() => {
        const fetchPostnummer = async () => {
            const postnummer = await stamdataPostnummerApi.getPostnummerSelect();
            const postnummerSelectOptions = toDropdownOptions(postnummer);
            setpostnummerSelectOptions(postnummerSelectOptions);
        }

        fetchPostnummer();
    }, [stamdataPostnummerApi]);

    useEffect(() => {
        setSelectedPostnummer(user.postnummer)
    }, [user])

    const updateEmailAsync = async(value: string): Promise<ApiResponse<boolean>> => {
        let result = await stamdataUserApi.editUserEmail(user.userId, value);
        if (result.data)
            NotificationModule.showSuccess(Localizer.global_userEmailUpdated(), "")
        else
            NotificationModule.showError(result.apiResponseMessage.errorMessage, "")
        return result;
    }

    const updatePhoneAsync = async (value: string): Promise<ApiResponse<boolean>> => {
        let result = await stamdataUserApi.editUserPhone(user.userId, value);
        if (result.data)
            NotificationModule.showSuccess(Localizer.global_userPhoneNumberUpdated(), "")
        else
            NotificationModule.showError(result.apiResponseMessage.errorMessage, "")
        return result;
    }

    const updateWorkEmailAsync = async (value: string): Promise<ApiResponse<boolean>> => {
        let result = await stamdataUserApi.editUserWorkEmail(user.userId, value);
        if (result.data)
            NotificationModule.showSuccess(Localizer.global_userWorkEmailUpdated(), "")
        else
            NotificationModule.showError(result.apiResponseMessage.errorMessage, "")
        return result;
    }

    const updateWorkPhoneAsync = async (value: string): Promise<ApiResponse<boolean>> => {
        let result = await stamdataUserApi.editUserWorkPhone(user.userId, value);
        if (result.data)
            NotificationModule.showSuccess(Localizer.global_userWorkPhoneNumberUpdated(), "")
        else
            NotificationModule.showError(result.apiResponseMessage.errorMessage, "")
        return result;
    }

    const updateInitialsAsync = async (value: string): Promise<ApiResponse<boolean>> => {
        let result = await stamdataUserApi.editInitials(user.userId, value);
        if (result.data) {
            NotificationModule.showSuccess(Localizer.global_userInitialsUpdated(), "");

            setUser((prev) => ({ ...prev, initialer: value }));

            if (isCurrentUser) {
                updateCurrentUserInitials(value);
            }
        }
        else
            NotificationModule.showError(result.apiResponseMessage.errorMessage, "")
        return result;
    }

    const updateAddress = async (value: string): Promise<ApiResponse<boolean>> => {
        let result = await stamdataUserApi.editUserAddress(user.userId, value);
        if (result.data)
            NotificationModule.showSuccess(Localizer.global_userAddressUpdated(), "")
        else
            NotificationModule.showError(result.apiResponseMessage.errorMessage, "")
        return result;
    }

    const updatePostnummer = async (newValue?: string) => {
        if (!newValue) return;
        setSelectedPostnummer(newValue);

        let result = await stamdataUserApi.editPostnummer(user.userId, newValue);
        if (result.data)
            NotificationModule.showSuccess(Localizer.global_userZipCodeUpdated(), "")
        else
            NotificationModule.showError(result.apiResponseMessage.errorMessage, "")
        return result;
    }

    const updateSamtykke = async () => {
        await stamdataUserApi.updateSamtykke(false);
        const accountService = new AccountService();
        accountService.Logout();
    }

    const samtykkeContent = () => {
        return (
            <div>
                <TextEditor id={samtykkeErklaeringModal + "editor"} isViewer={true}
                            markdown={Localizer.SamtykkeErklaering()}/>
                <div className={"modal-actions"}>
                    <div>
                        <button className={"btn btn-danger"} data-toggle="modal"
                                data-target={`#${tilbagekaldSamtykkeModal}`}>{Localizer.SamtykkeTilbagekald()}</button>
                    </div>
                </div>
            </div>
        );
    }

    const tilbagekaldSamtykkeContent = () => {
        return (
            <div>
                <div className="pre-line margin-bottom-m">
                    {Localizer.SamtykkeTilbagekaldInfo()}
                </div>

                <div className="modal-actions">
                    <div>
                        <button type="button" className="btn btn-default mr-2" data-dismiss="modal">
                            {Localizer.global_cancel()}
                        </button>
                        <button type="button" className="btn btn-danger" onClick={() => updateSamtykke()}>
                            {Localizer.global_OK()}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    const resetPassword = async() => {
        await setEmailSent(undefined)
        await setAsyncProcessing(true);
        let result = await stamdataUserAccountApi.resetPassword();
        if (result) {
            NotificationModule.showSuccess(Localizer.global_emailSentForPasswordReset(), "")
            await setEmailSent(true)
        } else {
            await setEmailSent(false)
        }
        await setAsyncProcessing(false)
    }

    const getMyData = async () => {
        const userId = props.user.userId;
        await logbogExportApi.getAllDataForUserByUserId(userId, props.user.getFullName());
        const newGdprLastFetchAt = await stamdataUserApi.updateGdprFetchedAt(userId);
        setGdprLastFetchAt(newGdprLastFetchAt);
    }





    const renderTableBodyRoller = () => {
        return (
            <tbody>
            {
                user.roller?.map((rolle) => {
                    return (
                        <tr key={rolle.id} data-toggle="modal" data-id={rolle.id}>
                            <td>{UserRoleToString(rolle.rolleType)}</td>
                            <td>{UserRoleTitleToString(rolle.rolleTitel)}</td>
                            <td>{rolle.regioner?.map((region ,i) =>
                                <span className="badge badge-pill badge-primary margin-right-xs">{region}</span>
                            )}</td>
                            <td>{rolle.omraader?.map((omraade ,i) =>
                                <span className="badge badge-pill badge-primary margin-right-xs">{omraade}</span>
                            )}</td>
                            <td>{rolle.praksis?.map((praksis, i) =>
                                <span className="badge badge-pill badge-primary margin-right-xs">{praksis}</span>
                            )}</td>
                            <td>{rolle.sygehuse?.map((sygehus, i) =>
                                <span className="badge badge-pill badge-primary margin-right-xs">{sygehus}</span>
                            )}</td>
                            <td>{rolle.afdelinger?.map((afdeling, i) =>
                                <span className="badge badge-pill badge-primary margin-right-xs">{afdeling}</span>
                            )}</td>
                            <td>{rolle.specialer?.map((speciale, i) =>
                                <span className="badge badge-pill badge-primary margin-right-xs">{speciale}</span>
                            )}</td>
                        </tr>
                    );
                })
            }
            </tbody>
        );
    }

    const renderTableBodyVejledertilknytninger = () => {
        return (
            <tbody>
            {
                user.vejledertilknytninger?.map((tilknytning) => {
                    return (
                        <tr key={tilknytning.vejledertilknytningId} data-toggle="modal" data-id={tilknytning.vejledertilknytningId}>
                            <td>{tilknytning.sygehus}</td>
                            <td>{tilknytning.laegeinstitution}</td>
                            <td>{tilknytning.slutDatoUtc ?? Localizer.global_ubegraenset()}</td>
                            <td>{tilknytning.alleLaeger
                                ? <span className="badge badge-pill badge-primary margin-right-xs">Alle læger</span>
                                : tilknytning.laeger.map((laege ,i) =>
                                <span className="badge badge-pill badge-primary margin-right-xs">{laege}</span>
                            )}</td>
                        </tr>
                    );
                })
            }
            </tbody>
        );
    }

    const renderTableBodyForloebAdgange = () => {
        return (
            <tbody>
            {
                user.forloebAdgange?.map((forloebadgang) => {
                    return (
                        <tr key={forloebadgang.id} data-toggle="modal" data-id={forloebadgang.id}>
                            <td>{forloebadgang.stillingsnummer}</td>
                            <td>{forloebadgang.Uddannelsessted}</td>
                            <td>{forloebadgang.uddannelsesType}</td>
                            <td>{forloebadgang.adgangTilDato}</td>
                        </tr>
                    );
                })
            }
            </tbody>
        );
    }



    return (
        <>
            <div className="card top-level-container">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="row">
                                <div className="col-sm-12 mb-4">
                                    <h4>{Localizer.global_profile()}</h4>
                                </div>
                                <UserProfileImage user={user}/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row mb-3">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="userProfile_givenName">{Localizer.global_firstName()}</label>
                                        <input id="userProfile_givenName" name="givenName" className="form-control"
                                               value={user.firstName} disabled/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="userProfile_familyName">{Localizer.global_lastName()}</label>
                                        <input id="userProfile_familyName" name="familyName" className="form-control"
                                               value={user.lastName} disabled/>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="userProfile_initials">{Localizer.global_initials()}</label>
                                        <AsyncInput id="userProfile_initials"
                                                    name="initials"
                                                    value={user.initialer}
                                                    actionExecute={updateInitialsAsync}
                                                    isDisabled = {!isAdminOrVusOrCurrentUser}
                                                    maxValueLenght={4}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="userProfile_authorisationId">{Localizer.global_authorisationId()}</label>
                                        <input id="userProfile_authorisationId" name="authorisationId"
                                               className="form-control"
                                               value={user.authorizationId} disabled/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label
                                            htmlFor="userProfile_foedselsdato">{Localizer.global_foedselsdato()}</label>
                                        <input id="userProfile_foedselsdato" name="foedselsdato"
                                               className="form-control"
                                               value={user.foedselsdato} disabled/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label
                                            htmlFor="userProfile_kburunde">{Localizer.global_kburunde()}</label>
                                        <input id="userProfile_kburunde" name="kburunde"
                                               className="form-control"
                                               value={(user.kbuRundePeriode && user.kbuRundeAar)? GetKbuRundeEnumTranslation(user.kbuRundePeriode) + " " + user.kbuRundeAar : Localizer.kbuRundeIngen()} disabled/>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label
                                            htmlFor="userProfile_postnummer">{Localizer.global_postnummer()}
                                        </label>
                                        {isAdminOrVusOrCurrentUser &&
                                            <Dropdown
                                                placeholder={Localizer.vaelgPostnummer()}
                                                options={postnummerSelectOptions}
                                                onChange={(selectedOption) => updatePostnummer(selectedOption?.value)}
                                                isClearable={false}
                                                value={postnummerSelectOptions.find(x => x.value === selectedPostnummer)}
                                            />
                                        }
                                        {!isAdminOrVusOrCurrentUser &&
                                            <input id="userProfile_addresse" name="addresse" className="form-control"
                                                   value={user.postnummer} disabled/>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="userProfile_addresse">{Localizer.global_address()}</label>
                                        <AsyncInput id="userProfile_addresse"
                                                    name="addresse"
                                                    value={user.address}
                                                    actionExecute={updateAddress}
                                                    isDisabled = {!isAdminOrVusOrCurrentUser}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label
                                            htmlFor="userProfile_mobilePhone">{Localizer.global_mobilePhone()}
                                        </label>
                                        <AsyncInput id="userProfile_mobilePhone"
                                                    name="mobilePhone"
                                                    value={user.phone}
                                                    actionExecute={updatePhoneAsync}
                                                    isDisabled = {!isAdminOrVusOrCurrentUser}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="userProfile_email">{Localizer.global_email()}</label>
                                        <AsyncInput id="userProfile_email"
                                                    name="email"
                                                    value={user.email}
                                                    actionExecute={updateEmailAsync}
                                                    isDisabled = {!isAdminOrVusOrCurrentUser}
                                        />
                                        <Caption text={Localizer.userProfilePage_emailCaption()}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label
                                            htmlFor="userProfile_workPhone">{Localizer.global_workPhone()}
                                        </label>
                                        <AsyncInput id="userProfile_workPhone"
                                                    name="workPhone"
                                                    value={user.workPhone ? user.workPhone : Localizer.userProfilePage_noWorkPhone()}
                                                    actionExecute={updateWorkPhoneAsync}
                                                    isDisabled = {!isAdminOrVusOrCurrentUser}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="userProfile_workEmail">{Localizer.global_workEmail()}</label>
                                        <AsyncInput id="userProfile_workEmail"
                                                    name="workEmail"
                                                    value={user.workEmail ? user.workEmail : Localizer.userProfilePage_noWorkEmail()}
                                                    actionExecute={updateWorkEmailAsync}
                                                    isDisabled = {!isAdminOrVusOrCurrentUser}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {!isMobile &&
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="row">
                                    <div className="col-sm-12 mb-4">
                                        <h4>{Localizer.global_consent()}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <div className={"card shadow-none m-0"}>
                                                <div className={"card-body d-flex p-2 justify-content-between"}>
                                                    <div className={"d-flex"}>
                                                        <div className={"d-flex"}>
                                                            {user.harGivetSamtykke &&
                                                                <>
                                                                    <div className="round success">
                                                                        <EmblaIcon iconName="success"/>
                                                                    </div>
                                                                    <h5 className="ml-2 subtle align-self-center">{Localizer.profilepage_GivetSamtykke()}</h5>
                                                                </>
                                                            }
                                                            {!user.harGivetSamtykke &&
                                                                <>
                                                                    <div className="round warning">
                                                                        <EmblaIcon iconName="warning"/>
                                                                    </div>
                                                                    <h5 className="ml-2 subtle align-self-center">{Localizer.profilepage_IkkeGivetSamtykke()}</h5>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    {user.userId === currentUser.UserId &&
                                                        <button
                                                            className="btn btn-outline-primary fit-content-min-width"
                                                            data-toggle="modal"
                                                            data-target={`#${samtykkeErklaeringModal}`}>{Localizer.profilepage_SeBetingelser()}</button>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {currentUser.UserId === props.user.userId &&
                    <>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="row">
                                        <div className="col-sm-12 mb-4">
                                            <h4>{Localizer.global_resetPassword()}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-6 align-self-end margin-top-s d-flex">
                                                        <div className={"d-flex"}>
                                                            <button disabled={emailSent}
                                                                    className="btn btn-outline-primary margin-right-m"
                                                                    onClick={resetPassword}>
                                                                {Localizer.global_resetPassword()}
                                                            </button>

                                                            {emailSent !== undefined && emailSent &&
                                                                <div className="success flex-align-c">
                                                                    <div>
                                                                        <EmblaIcon iconName="success"/>
                                                                    </div>
                                                                    <h4 className={"margin-left-s"}>{Localizer.global_emailSent()}</h4>
                                                                </div>
                                                            }
                                                            {emailSent !== undefined && !emailSent &&
                                                                <div className="warning">
                                                                    <EmblaIcon iconName="warning"/>
                                                                </div>
                                                            }
                                                            {asyncProcessing &&
                                                                <Spinner
                                                                    text={Localizer.global_senderEmail()}
                                                                    spinnerSize="exstra-small"
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {currentUser.IsLaege() &&
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="row">
                                            <div className="col-sm-12 mb-4">
                                                <h4>{Localizer.global_FetchMyData()}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-12 align-self-end margin-top-s d-flex">
                                                            <div>
                                                                <Title subTitle={gdprLastFetchAt ? "" : Localizer.global_dataNotPreviouslyFetched()}>
                                                                    <div className="d-flex margin-bottom-s">
                                                                        <AsyncButton
                                                                            overrideClasses={"btn btn-outline-primary margin-right-m"}
                                                                            clickFunction={getMyData}
                                                                            buttonText={Localizer.global_fetchAllDataRelatedToUser()}
                                                                            waitText={isMobile ? Localizer.global_gettingData() : Localizer.global_gettingDataWithWaitingtime()}
                                                                            showWaitTextIfMobile
                                                                        />
                                                                    </div>
                                                                    {gdprLastFetchAt &&
                                                                        <CalendarTimeDisplay dato={gdprLastFetchAt} startTime={gdprLastFetchAt} />
                                                                    }
                                                                </Title>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>

            {props.user.roller !== undefined && props.user.roller.length > 0 && (
                <div>
                    <div className={"card card-no-margin margin-top-l border-bottom-0"}>
                        <div className={"card-body"}>
                            <h3>{Localizer.global_brugerroller()}</h3>
                        </div>
                    </div>
                    <Table
                        responsive={true}
                        tableIdentifier={`roles-table-${props.user.userId}`}
                        columnHeaders={[
                            new DataTableHeader(Localizer.global_rolle(), false),
                            new DataTableHeader(Localizer.global_title(), false),
                            new DataTableHeader(Localizer.global_regioner(), false),
                            new DataTableHeader(Localizer.global_omraader(), false),
                            new DataTableHeader(Localizer.global_praksis(), false),
                            new DataTableHeader(Localizer.global_sygehuse(), false),
                            new DataTableHeader(Localizer.global_afdelinger(), false),
                            new DataTableHeader(Localizer.global_specialer(), false)
                        ]}
                        renderTableBody={() => renderTableBodyRoller()}
                        showLengthChange={false}
                        showPaging={false}
                        showSearching={false}
                        hideHeaderBodies={true}
                        showFilter={false}
                    />
                </div>
            )}

            {props.user.vejledertilknytninger !== undefined && props.user.vejledertilknytninger.length > 0 && (
                <div>
                    <div className={"card card-no-margin margin-top-l border-bottom-0"}>
                        <div className={"card-body"}>
                            <h3>{Localizer.global_vejledertilknytninger()}</h3>
                        </div>
                    </div>
                    <Table
                        responsive={true}
                        tableIdentifier={`vejleder-tilknytninger-table-${props.user.userId}`}
                        columnHeaders={[
                            new DataTableHeader(Localizer.global_sygehus(), false),
                            new DataTableHeader(Localizer.global_uddannelsessted(), false),
                            new DataTableHeader(Localizer.global_tilknytningudloeber(), false),
                            new DataTableHeader(Localizer.global_laeger(), false)
                        ]}
                        renderTableBody={() => renderTableBodyVejledertilknytninger()}
                        showLengthChange={false}
                        showPaging={false}
                        showSearching={false}
                        hideHeaderBodies={true}
                        showFilter={false}
                    />
                </div>
            )}

            {props.user.forloebAdgange !== undefined && props.user.forloebAdgange.length > 0 && (
                <div>
                    <div className={"card card-no-margin margin-top-l border-bottom-0"}>
                        <div className={"card-body"}>
                            <h3>{Localizer.global_tidsbegraensedeforloebadgange()}</h3>
                        </div>
                    </div>
                    <Table
                        responsive={true}
                        tableIdentifier={`forloeb-adgange-table-${props.user.userId}`}
                        columnHeaders={[
                            new DataTableHeader(Localizer.global_stillingNummer(), false),
                            new DataTableHeader(Localizer.global_uddannelsessted(), false),
                            new DataTableHeader(Localizer.global_uddannelsetype(), false),
                            new DataTableHeader(Localizer.global_adgangudloeber(), false)
                        ]}
                        renderTableBody={() => renderTableBodyForloebAdgange()}
                        showLengthChange={false}
                        showPaging={false}
                        showSearching={false}
                        hideHeaderBodies={true}
                        showFilter={false}
                    />
                </div>
            )}

            <SlideIn id={samtykkeErklaeringModal} modalSize={ModalSize.md} title={Localizer.global_betingelser()}
                     bodyContent={samtykkeContent()}/>
            <SlideIn id={tilbagekaldSamtykkeModal} title={Localizer.SamtykkeTilbagekald()}
                     bodyContent={tilbagekaldSamtykkeContent()} modalType={ModalType.originalModal}/>
        </>
    );
}
