import './App.css';
import MainRouting from "./core/infrastructure/routing/mainRouting";
import {Localizer} from "./infrastructure/localization/localizer";
import "ditmer-embla";
import {StorageKeys} from './infrastructure/storage/storageKeys';
import {InitLeftMenu} from "./core/layout/leftMenu/leftMenuSlice";
import * as Sentry from "@sentry/react";
import config from "./config/config";
import AppInsightsProviderWrapper from 'services/applicationInsights/appInsightsProviderWrapper';
import UserContextProvider from "./core/components/user/userContextProvider";
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'core/infrastructure/errors/errorBoundary';
import NotifikationContextProvider from 'core/components/notifications/context/notifikationContextProvider';
import CookieConsentContextProvider from 'core/components/cookies/context/CookieConsentContextProvider';

const useAppSetup = () => {
    Sentry.init({
        dsn: config.sentryDsn,
        defaultIntegrations: [new Sentry.Integrations.GlobalHandlers({
            onunhandledrejection: false,
            onerror: false
        })] //TODO: Forsøg på at logge unhandledrejections bedre jvnf: https://docs.sentry.io/platforms/javascript/guides/react/usage/ og https://docs.sentry.io/platforms/javascript/configuration/integrations/default/
    });

    Localizer.Init();
    InitLeftMenu((sessionStorage.getItem(StorageKeys.leftMenuCollapsed) === 'true') as boolean);
}

function App() {
    useAppSetup();

    return (
        <CookieConsentContextProvider>
            <AppInsightsProviderWrapper>
                <UserContextProvider>
                    <NotifikationContextProvider>
                        <Router>
                            <ErrorBoundary>
                                <MainRouting/>
                            </ErrorBoundary>
                        </Router>
                    </NotifikationContextProvider>
                </UserContextProvider>
            </AppInsightsProviderWrapper>
        </CookieConsentContextProvider>
    );
}



export default App;
