import { LocalizationApi } from "services/localizationService";
import { StorageKeys } from "../storage/storageKeys";
import { LocalizerSpecifics } from "./localizerSpecifics";

export function getTranslation(key: string, defaultValue: string): string {
    return Localizer.translations[key] ?? defaultValue;
}

export class Localizer extends LocalizerSpecifics {
    public static translations: { [key: string]: string } = {};

    public static async Init() {
        const localizationAsJson = localStorage.getItem(StorageKeys.localizations)
        if (localizationAsJson) {
            this.translations = JSON.parse(localizationAsJson);
        } else {
            let localizationApi = new LocalizationApi()
            this.translations = await localizationApi.getLocalizations();
            localStorage.setItem(StorageKeys.localizations, JSON.stringify(this.translations));
        }
    }

    public static global_internalServerError = () => getTranslation("global_internalServerError", "Der skete en fejl i kommunikationen med serveren. Prøv venligst igen eller kontakt en administrator.");

    public static global_cancel = () => getTranslation("global_cancel", "Annuller");
    public static global_evaluer = () => getTranslation("global_evaluer", "Evaluer");
    public static global_evalueret = () => getTranslation("global_evalueret", "Evalueret");
    public static global_ikkeEvalueret = () => getTranslation("global_ikkeEvalueret", "Ikke evalueret");
    public static global_fortryd = () => getTranslation("global_fortryd", "Fortryd");
    public static global_remove = () => getTranslation("global_remove", "Fjern");
    public static global_email = () => getTranslation("global_email", "Email");
    public static global_lastLogin = () => getTranslation("global_lastLogin", "Sidste login");
    public static global_CPR = () => getTranslation("global_CPR", "CPR nr.");
    public static global_user = () => getTranslation("global_user", "Bruger");
    public static global_users = () => getTranslation("global_users", "Brugere");
    public static global_dashboard = () => getTranslation("global_dashboard", "Dashboard");
    public static global_profile = () => getTranslation("profile", "Profil");
    public static global_uploadProfileImage = () => getTranslation("global_uploadProfileImage", "Tilføj profilbillede");
    public static global_userEmailUpdated = () => getTranslation("global_userEmailUpdated", "Brugerens email blev opdateret");
    public static global_userWorkEmailUpdated = () => getTranslation("global_userWorkEmailUpdated", "Brugerens arbejdsemail blev opdateret");
    public static global_userPhoneNumberUpdated = () => getTranslation("global_userPhoneNumberUpdated", "Brugerens telefonnummer blev opdateret");
    public static global_userWorkPhoneNumberUpdated = () => getTranslation("global_userWorkPhoneNumberUpdated", "Brugerens arbejdstelefonnummer blev opdateret");
    public static global_userInitialsUpdated = () => getTranslation("global_userInitialsUpdated", "Brugerens initialer blev opdateret");
    public static global_emailSentForPasswordReset = () => getTranslation("global_emailSentForPasswordReset", "Email sendt til nulstilling af password");
    public static global_logout = () => getTranslation("global_logout", "Log ud");
    public static global_login = () => getTranslation("global_login", "Log ind");
    public static global_stopImpersonation = () => getTranslation("global_stopImpersonation", "Stop 'Log på som bruger i Uddannelseslæge.dk'");
    public static global_error = () => getTranslation("global_error", "Fejl");
    public static global_forloeb = () => getTranslation("global_forloeb", "Forløb");
    public static global_forloebshistorik = () => getTranslation("global_forloebshistorik", "Forløbshistorik");
    public static global_forloebList = () => getTranslation("global_forloebList", "Forløbsliste");
    public static global_name = () => getTranslation("global_name", "Navn");
    public static global_firstName = () => getTranslation("global_firstName", "Fornavn");
    public static global_filter = () => getTranslation("global_filter", "Filter");
    public static global_noFilters = () => getTranslation("global_noFilters", "Ingen filtre");
    public static global_loadingFilters = () => getTranslation("global_loadingFilters", "Henter filtreringsmuligheder");
    public static global_clearFilters = () => getTranslation("global_clearFilters", "Fjern filtre");
    public static global_unoccupied_stillinger = () => getTranslation("Vis ubesatte stillinger","Vis også ubesatte stillinger");
    public static global_getFilters = () => getTranslation("global_getFilters", "Henter filtrer");
    public static global_getFiles = () => getTranslation("global_getFiles", "Henter filer");
    public static global_lastName = () => getTranslation("global_lastName", "Efternavn");
    public static global_initials = () => getTranslation("global_initials", "Initialer");
    public static global_foedselsdato = () => getTranslation("global_foedselsdato", "Fødselsdato");
    public static global_kburunde = () => getTranslation("global_kburunde", "KBU runde");
    public static global_authorisationId = () => getTranslation("global_authorisationId", "Autorisations ID");
    public static global_mobilePhone = () => getTranslation("global_mobilePhone", "Mobiltelefon");
    public static global_workPhone = () => getTranslation("global_workPhone", "Arbejdstelefon");
    public static global_workEmail = () => getTranslation("global_workEmail", "Arbejdsmail");
    public static global_laegeroller = () => getTranslation("global_roles", "Lægeroller");
    public static global_systemAdmin = () => getTranslation("global_systemAdmin", "Systemadministrator");
    public static global_admin = () => getTranslation("global_admin", "Administrator");
    public static global_uddannelseslaege = () => getTranslation("global_uddannelseslaege", "Uddannelseslæge");
    public static global_uddannelsesansvarlig = () => getTranslation("global_uddannelsesansvarlig", "Uddannelsesansvarlig");
    public static global_uddannelsesansvarligOverlaege = () => getTranslation("global_uddannelsesansvarligOverlaege", "Uddannelsesansvarlig overlæge");
    public static global_tutorlaege = () => getTranslation("global_tutorlaege", "Tutorlæge");
    public static global_vejleder = () => getTranslation("global_vejleder", "Vejleder");
    public static global_Uao = () => getTranslation("global_Uao", "UAO");
    public static global_Uko = () => getTranslation("global_Uko", "UKO");
    public static global_Pkl = () => getTranslation("global_Pkl", "PKL");
    public static global_Puk = () => getTranslation("global_Puk", "PUK");
    public static global_Amu = () => getTranslation("global_Amu", "AMU");
    public static global_Ukyl = () => getTranslation("global_Ukyl", "UKYL");
    public static global_lektor = () => getTranslation("global_lektor", "Lektor");
    public static global_dynamu = () => getTranslation("global_dynamu", "DYNAMU");
    public static global_hovedKursusLeder = () => getTranslation("global_hovedKursusLeder", "Hovedkursusleder");
    public static global_delKursusLeder = () => getTranslation("global_delKursusLeder", "Delkursusleder");
    public static global_sekretaerForHovedKursusLeder = () => getTranslation("global_sekretaerForHovedKursusLeder", "Sekretær for hovedkursusleder");
    public static global_chefLaege = () => getTranslation("global_chefLaege", "Cheflæge");
    public static global_ledendeOverlaege = () => getTranslation("global_ledendeOverlaege", "Ledende overlæge");
    public static global_vusMedarbejder = () => getTranslation("global_vusMedarbejder", "VUS");
    public static global_sekretaer = () => getTranslation("global_sekretaer", "Sekretær");
    public static global_ledelse = () => getTranslation("global_ledelse", "Ledelse");
    public static global_hospitalLedelse = () => getTranslation("global_hospitalLedelse", "Hospitalsledelse");
    public static global_konsulentForDenLaegeligeVidereuddannelse = () => getTranslation("global_konsulentForDenLaegeligeVidereuddannelse", "Konsulent for den lægelige videreuddannelse");
    public static global_hrMedarbejder = () => getTranslation("global_hrMedarbejder", "HR-medarbejder");
    public static global_forskningstraeningsvejleder = () => getTranslation("global_forskningstraeningsvejleder", "Forskningstræningsvejleder");
    public static global_kursus = () => getTranslation("global_kursus", "Kursus");
    public static global_kursusleder = () => getTranslation("global_kursusleder", "Kursusleder");
    public static global_kursusledere = () => getTranslation("global_kursusledere", "Kursusledere ");
    public static global_vejlederen = () => getTranslation("global_vejlederen", "Vejlederen");
    public static global_vejledere = () => getTranslation("global_vejledere", "Vejledere");
    public static global_hovedvejleder = () => getTranslation("global_hovedvejleder", "Hovedvejleder");
    public static global_klarmeldinger = () => getTranslation("global_klarmeldinger", "Klarmeldinger");
    public static global_allekompetencer = () => getTranslation("global_allekompetencer", "Alle kompetencer");
    public static global_evaluering = () => getTranslation("global_evaluering", "Evaluering");
    public static global_vejledersamtale = () => getTranslation("global_vejledersamtale", "Vejledersamtale");
    public static global_vejledersamtaler = () => getTranslation("global_vejledersamtaler", "Vejledersamtaler");
    public static global_uddannelsesplan = () => getTranslation("global_uddannelsesplan", "Uddannelsesplan");
    public static global_uddannelsestype = () => getTranslation("global_uddannelsestype", "Uddannelsestype");
    public static global_uddannelsestyper = () => getTranslation("global_uddannelsestype", "Uddannelsestyper");
    public static global_uddannelsestypeFilter = () => getTranslation("global_uddannelsestypeFilter", "Filtrer på uddannelsestype");
    public static global_uddannelsesplaner = () => getTranslation("global_uddannelsesplaner", "Uddannelsesplaner");
    public static global_vejledning = () => getTranslation("global_vejledning", "Vejledning");
    public static global_notater = () => getTranslation("global_notater", "Notater");
    public static global_letters = () => getTranslation("global_letters", "Breve");
    public static global_alleNotater = (antalNotater: string) => getTranslation("global_alleNotater", "Notater (" + antalNotater + ")");
    public static global_planlaeg = () => getTranslation("global_planlaeg", "Planlæg");
    public static global_planlagt = () => getTranslation("global_planlagt", "Planlagt");
    public static global_standard = () => getTranslation("global_standard", "Standard");
    public static global_skalBehandles = () => getTranslation("global_skalBehandles", "Skal behandles");
    public static global_edit = () => getTranslation("global_edit", "Rediger");
    public static global_noComment = () => getTranslation("global_noComment", "Ingen kommentar");
    public static global_maalbeskrivelse = () => getTranslation("global_maalbeskrivelse", "Målbeskrivelse");
    public static global_vaelgMaalbeskrivelse = () => getTranslation("global_vaelgMaalbeskrivelse", "Vælg målbeskrivelse");
    public static global_maalbeskrivelser = () => getTranslation("global_maalbeskrivelse", "Målbeskrivelser");
    public static global_ingenMaalbeskrivelser = () => getTranslation("global_ingenMaalbeskrivelser", "Ingen målbeskrivelser");
    public static global_editing = () => getTranslation("global_editing", "Redigerer");
    public static global_creating = () => getTranslation("global_editing", "Opretter");
    public static global_consent = () => getTranslation("global_consent", "Samtykke");
    public static global_resetPassword = () => getTranslation("global_resetPassword", "Nulstil adgangskode");
    public static global_senderEmail = () => getTranslation("global_senderEmail", "Sender email");
    public static global_lastFetched = () => getTranslation("global_lastFetched", "Sidst hentet");
    public static global_FetchMyData = () => getTranslation("global_FetchMyData", "Hent mine data");
    public static global_fetchAllDataRelatedToUser = () => getTranslation("global_fetchAllDataRelatedToUser", "Hent alt data relateret til din bruger");
    public static global_dataNotPreviouslyFetched = () => getTranslation("global_dataNotPreviouslyFetched", "Ikke tidligere hentet");
    public static global_gettingDataWithWaitingtime = () => getTranslation("global_gettingDataWithWaitingtime", "Henter data, dette kan tage et par minuter");
    public static global_gettingData = () => getTranslation("global_gettingDataWithWaitingtime", "Henter data");
    public static global_seAlle = () => getTranslation("global_seAlle", "Se alle");
    public static global_emailSent = () => getTranslation("global_emailSent", "Email sendt");
    public static global_emailSentToUsers = (amountOfUsers: number) => getTranslation("global_emailSentToUsers", `Email afsendt til ${amountOfUsers} ${amountOfUsers > 1 ? "brugere" : "bruger"}`);
    public static global_uddannelseslaeger = () => getTranslation("global_uddannelseslaeger", "Uddannelseslæger");
    public static global_addComment = () => getTranslation("global_addComment", "Tilføj kommentar");
    public static global_comment = () => getTranslation("global_comment", "Kommentar");
    public static global_all = () => getTranslation("global_all", "Alle");
    public static global_current = () => getTranslation("global_current", "Nuværende");
    public static global_future = () => getTranslation("global_future", "Fremtidige");
    public static global_former = () => getTranslation("global_former", "Tidligere");
    public static global_active = () => getTranslation("active", "Aktiv");
    public static global_yes = () => getTranslation("global_yes", "Ja");
    public static global_no = () => getTranslation("global_no", "Nej");
    public static global_clear = () => getTranslation("clear", "Ryd");
    public static global_kompetencer = () => getTranslation("global_kompetencer", "Kompetencer");
    public static global_godkendteKompetencer = () => getTranslation("global_godkendteKompetencer", "Godkendte kompetencer");
    public static global_kompetence = () => getTranslation("global_kompetence", "Kompetence");
    public static global_kurser = () => getTranslation("global_kurser", "Kurser");
    public static global_kursustitel = () => getTranslation("global_kursustitel", "Kursus titel");
    public static global_kursusGruppeTitel = () => getTranslation("global_kursusGruppeTitel", "Kursusgruppe titel");
    public static global_kursusudbyder = () => getTranslation("global_kursusudbyder", "Kursusudbyder");
    public static global_kursusudbydere = () => getTranslation("global_kursusudbydere", "Kursusudbydere ");
    public static global_merit = () => getTranslation("global_merit", "Merit");
    public static global_next = () => getTranslation("global_next", "Næste");
    public static global_indsend = () => getTranslation("global_indsend", "Indsend");
    public static global_indsendt = () => getTranslation("global_indsendt", "Indsendt");
    public static global_kompetenceDescription = () => getTranslation("global_kompetenceDescription", "Kompetencebeskrivelse");
    public static global_kompetenceTitle = () => getTranslation("global_kompetenceTitle", "Kompetencetitel");
    public static global_kompetenceNummer = () => getTranslation("global_kompetenceNummer", "Kompetencenummer");
    public static global_konkretisering = () => getTranslation("global_konkretisering", "Konkretisering");
    public static global_teachingStrategy = () => getTranslation("global_teachingStrategy", "Læringsstrategi");
    public static global_kompetenceVurderingsMetoder = () => getTranslation("global_kompetenceVurderingsMetoder", "Kompetencevurderingsmetoder");
    public static global_back = () => getTranslation("global_back", "Tilbage");
    public static global_forloebOverview = () => getTranslation("global_forloebOverview", "Forløbsoverblik");
    public static global_overview = () => getTranslation("global_overview", "Overblik");
    public static global_uddannelseslaegedk = () => getTranslation("global_uddannelseslaegedk", "Uddannelseslæge.dk");
    public static global_uddannelseslaegedkurl = () => getTranslation("global_uddannelseslaegedkurl", "https://uddannelseslaege.dk/");
    public static global_evalueringsstatistik = () => getTranslation("global_evalueringsstatistik", "Evalueringsstatistik");
    public static global_createNew = () => getTranslation("global_createNew", "Opret ny");
    public static global_searching = () => getTranslation("global_searching", "Søger");
    public static global_noResult = () => getTranslation("global_noResult", "Ingen resultater");
    public static global_periode = () => getTranslation("global_periode", "Periode");
    public static global_iPerioden = () => getTranslation("global_iPerioden", "i perioden");
    public static global_antal = () => getTranslation("global_antal", "Antal");
    public static global_tilfoejetAf = () => getTranslation("global_tilfoejetAf", "Tilføjet af");
    public static global_dato = () => getTranslation("global_dato", "Dato");
    public static global_stillinger = () => getTranslation("global_stillinger", "Stillinger");
    public static global_vaelgFil = () => getTranslation("global_vaelgFil", "Vælg fil...");
    public static global_stillingNummer = () => getTranslation("global_stillingNummer", "Stillingsnummer");
    public static global_timerPrUge = () => getTranslation("global_timerPrUge", "Timer pr. uge");
    public static global_speciale = () => getTranslation("global_speciale", "Speciale");
    public static global_omraade = () => getTranslation("global_omraade", "Område");
    public static global_uddannelsessted = () => getTranslation("global_uddannelsessted", "Uddannelsessted");
    public static global_omraader = () => getTranslation("global_omraader", "Områder");
    public static global_ansaettelsessted = () => getTranslation("global_ansaettelsessted", "Ansættelsessted");
    public static global_ansaettelsessteder = () => getTranslation("global_ansaettelsessteder", "Ansættelsessteder");
    public static global_uddannelsetype = () => getTranslation("global_uddannelsetype", "Uddannelsestype");
    public static global_introduktion = () => getTranslation("global_introduktion", "Introduktion");
    public static global_introduktionsuddannelsen = () => getTranslation("global_introduktionsuddannelsen", "Introduktionsuddannelsen");
    public static global_kliniskBasisuddannelse = () => getTranslation("global_kliniskBasisuddannelse", "Klinisk basisuddannelse");
    public static global_specialespecifikkeIntrouddannelsen = () => getTranslation("global_specialespecifikkeIntrouddannelsen", "Specialespecifikke kurser - Introuddannelsen");
    public static global_almenMedicin = () => getTranslation("global_specialespecifikkeIntrouddannelsen", "Almen medicin");
    public static global_justerende = () => getTranslation("global_justerende", "Justerende");
    public static global_boerAfholdesIndenTreMaaneder = () => getTranslation("global_boerAfholdesIndenTreMaaneder", "Bør afholdes inden for tre måneder");
    public static global_boerAfholdesIStartenAfForloebet = () => getTranslation("global_boerAfholdesIStartenAfForloebet", "Bør afholdes i starten af forløbet");
    public static global_afsluttende = () => getTranslation("global_afsluttende", "Afsluttende");
    public static global_boerAfholdesIndenForloebetErAfsluttet = () => getTranslation("global_boerAfholdesIndenForloebetErAfsluttet", "Bør afholdes inden forløbet er afsluttet");
    public static global_getChoices = () => getTranslation("global_getChoices", "Henter valgmuligheder");
    public static global_select = () => getTranslation("global_select", "Vælg");
    public static global_delete = () => getTranslation("global_delete", "Slet");
    public static global_genskab = () => getTranslation("global_genskab", "Genskab");
    public static global_save = () => getTranslation("global_save", "Gem");
    public static global_saveComment = () => getTranslation("global_saveComment", "Gem kommentar");
    public static global_SaveAndApproveKursus = () => getTranslation("global_fillAndSave", "Gem & godkend kursus");
    public static global_accept = () => getTranslation("global_accept", "Godkend");
    public static global_resterende = () => getTranslation("global_resterende", "Resterende");
    public static global_foraeldede = () => getTranslation("global_foraeldede", "Forældede");
    public static global_agree = () => getTranslation("global_accept", "Enig");
    public static global_notAgree = () => getTranslation("global_notAgree", "Ikke enig");
    public static global_afholdt = () => getTranslation("global_afholdt", "Afholdt");
    public static global_ikkeAfholdt = () => getTranslation("global_ikkeAfholdt", "Ikke afholdt");
    public static global_uddannelse = () => getTranslation("global_uddannelse", "Uddannelse");
    public static global_uddannelser = () => getTranslation("global_uddannelser", "Uddannelser");
    public static global_sted = () => getTranslation("global_sted", "Sted");
    public static global_getUddannelser = () => getTranslation("global_getUddannelser", "Henter uddannelser");
    public static global_getDokumentation = () => getTranslation("global_getDokumentation", "Henter dokumentation");
    public static global_getEvalueringsstatistik = () => getTranslation("global_getEvalueringsstatistik", "Henter evalueringsstatistik");
    public static evalueringPage_henterData = () => getTranslation("evalueringPage_henterData", "Henter evalueringsdata");
    public static evalueringPage_valgfritDatoFelt = () => getTranslation("evalueringPage_valgfritDatoFelt", "Angiv eventuelt dato");
    public static evalueringPage_angivDatoFelt = () => getTranslation("evalueringPage_angivDatoFelt", "Angiv dato");
    public static evalueringPage_datoForGodkendelsesstatus = () => getTranslation("evalueringPage_datoForGodkendelsesstatus", "Dato for angivelse af godkendelsesstatus");
    public static evalueringPage_angivNummeriskVaerdi = () => getTranslation("evalueringPage_angivNummeriskVaerdi", "Angiv svar med nummerisk værdi");
    public static evalueringPage_angivTekstsvar = () => getTranslation("evalueringPage_angivTekstsvar", "Angiv tekstsvar");
    public static evalueringPage_nummeriskSvar = () => getTranslation("evalueringPage_nummeriskSvar", "Spørgsmål med nummerisk svar");
    public static evalueringPage_kompetenceevalueringUdfyldtKlikForMereInfo = () => getTranslation("evalueringPage_kompetenceevalueringUdfyldtKlikForMereInfo", "Kompetencevurdering afsluttet. Klik for mere information.");
    public static evalueringPage_section = () => getTranslation("evalueringPage_section", "Sektion");
    public static evalueringPage_answersInSection = () => getTranslation("evalueringPage_answersInSection", "Svar i sektion");
    public static evalueringPage_angivNummeriskSvar = () => getTranslation("evalueringPage_angivNummeriskSvar", "Angiv nummerisk svar");
    public static evalueringPage_angivStatusOgDatoOgGem = () => getTranslation("evalueringPage_angivStatusOgDatoOgGem", "Du skal angive status på godkendelse samt dato og klikke gem.");
    public static evalueringPage_jaNejSvarmulighed = () => getTranslation("evalueringPage_jaNejSvarmulighed", "ja/nej");
    public static evalueringPage_noComment = () => getTranslation("evalueringPage_noComment", "Ingen kommentar");
    public static evalueringPage_svarIndsendt = () => getTranslation("evalueringPage_svarIndsendt", "Svar opdateret");
    public static evalueringPage_kommentarIndsendt = () => getTranslation("evalueringPage_kommentarIndsendt", "Kommentar opdateret");
    public static evalueringPage_fejlVedBesvarelse = () => getTranslation("evalueringPage_fejlVedBesvarelse", "Fejl ved besvarelse, gå tilbage og prøv igen");
    public static evalueringPage_svarvaerdi = () => getTranslation("evalueringPage_svarvaerdi", "Svarværdi");
    public static evalueringPage_spoergsmaalsbesvarelser = () => getTranslation("evalueringPage_spoergsmaalsbesvarelser", "spørgsmålsbesvarelser");
    public static evalueringPage_alleSpoergsmaalHarEtSvar = () => getTranslation("evalueringPage_alleSpoergsmaalHarEtSvar", "Alle spørgsmål i denne sektion har et svar");
    public static evalueringPage_antalSvar = () => getTranslation("evalueringPage_antalSvar", "Antal svar");
    public static evalueringPage_updatingAnswer = () => getTranslation("evalueringPage_updatingAnswer", "Opdaterer svar");
    public static evalueringPage_svarUpdated = () => getTranslation("evalueringPage_svarUpdated", "Svar opdateret");
    public static evalueringPage_kanIkkeBedoemmes = () => getTranslation("evalueringPage_kanIkkeBedoemmes", "Kan ikke bedømmes");
    public static global_getDefaultEvalueringsstatistik = () => getTranslation("global_getDefaultEvalueringsstatistik", "Henter standardvisning for evalueringsstatistik");
    public static global_seUddannelse = () => getTranslation("global_seUddannelse", "Se uddannelse");
    public static global_evaluations = () => getTranslation("global_evaluations", "Vurderinger");
    public static global_notes = () => getTranslation("global_notes", "Notater");
    public static global_note = () => getTranslation("global_note", "Notat");
    public static global_detaljer = () => getTranslation("global_detaljer", "Detaljer");
    public static global_detaljerOgFiltre = () => getTranslation("global_detaljerOgFiltre", "Detaljer og filtre");
    public static global_seGennemsnitOgDetaljer = () => getTranslation("global_seDetaljer", "Se gennemsnit og filtre");
    public static global_files = () => getTranslation("global_files", "Filer");
    public static global_filesFromEducationPlace = () => getTranslation("global_filesFromEducationPlace", "Filer fra uddannelsesstedet");
    public static global_filesWithoutFolder = () => getTranslation("global_filesWithoutFolder", "Filer uden mappe");
    public static global_helpForms = () => getTranslation("global_helpForms", "Hjælpeskemaer");
    public static global_noFiles = () => getTranslation("global_noFiles", "Ingen filer");
    public static global_fileAlreadyUploaded = () => getTranslation("global_fileAlreadyUploaded", "Filen er allerede uploaded");
    public static global_fileType = () => getTranslation("global_fileType", "Filtype");
    public static global_selvstaendigtVirke = () => getTranslation("global_selvstaendigtVirke", "Selvstændigt virke");
    public static global_introduktionsstilling = () => getTranslation("global_introduktionsstilling", "Introduktionsstilling");
    public static global_speciallaegeanerkendelse = () => getTranslation("global_speciallaegeanerkendelse", "Speciallægeanerkendelse");
    public static global_beskaeftigelse = () => getTranslation("global_beskaeftigelse", "Beskæftigelse");
    public static global_description = () => getTranslation("global_description", "Beskrivelse");
    public static global_title = () => getTranslation("global_title", "Titel");
    public static global_status = () => getTranslation("global_status", "Status");
    public static global_add = () => getTranslation("global_add", "Tilføj");
    public static global_private = () => getTranslation("global_private", "Privat");
    public static global_visibilityAlwaysForDoctor = () => getTranslation("global_visibility", "Synlighed (Altid synligt for lægen)");
    public static global_visibilityUddannelseslaegeAndUddannelsesansvarlig = () => getTranslation("global_visibilityUddannelseslaegeAndUddannelsesansvarlig", "Synlighed (Altid synligt for uddannelseslæge og uddannelsesansvarlig)");
    public static global_visibileForAll = () => getTranslation("global_visibileForAll", "Synlig for alle");
    public static global_notRelevant = () => getTranslation("global_notRelevant", "Ikke relevant");
    public static global_chooseVisibility = () => getTranslation("global_chooseVisibility", "Vælg synlighed");
    public static global_tilknyttedeKompetencer = () => getTranslation("global_tilknyttedeKompetencer", "Tilknyttede kompetencer");
    public static global_tilknyttedeKompetence = () => getTranslation("global_tilknyttedeKompetence", "Tilknyttede kompetence");
    public static global_tilknyttet = () => getTranslation("global_tilknyttet", "Tilknyttet");
    public static global_tilknyttetKolon = () => getTranslation("global_tilknyttetKolon", "Tilknyttet:");
    public static global_personligeOplysninger = () => getTranslation("global_personligeOplysninger", "Personlige oplysninger");
    public static global_skiftTilRolle = (rolle: string) => getTranslation("global_skiftTilRolle", "Skift til " + rolle);
    public static global_brugereMedAdgang = () => getTranslation("global_brugereMedAdgang", "Brugere med adgang");
    public static global_brugere = () => getTranslation("global_brugere", "Brugere");
    public static global_brugereMedTidsbegraensetAdgang = () => getTranslation("global_brugereMedTidsbegraensetAdgang", "Brugere med tidsbegrænset adgang");
    public static global_region = () => getTranslation("global_region", "Region");
    public static global_regioner = () => getTranslation("global_regioner", "Regioner");
    public static global_regionerInDenmark = () => getTranslation("global_regionerInDenmark", "Regioner i Danmark");
    public static global_videreuddannelsesregion = () => getTranslation("global_videreuddannelsesregion", "Videreuddannelsesregion");
    public static global_removeKlarmeld = () => getTranslation("global_removeKlarmeld", "Fjern klarmelding");
    public static global_removeKlarmeldFromChosenKompetencer = () => getTranslation("global_removeKlarmeldFromChosenKompetencer", "Fjern klarmelding fra de valgte kompetencer");
    public static global_removeKlarmeldFromChosenKompetence = () => getTranslation("global_removeKlarmeldFromChosenKompetencer", "Fjern klarmelding fra valgt kompetence");
    public static global_removeGodkend = () => getTranslation("global_removeGodkend", "Fjern godkendelse");
    public static global_klarmeld = () => getTranslation("global_klarmeld", "Klarmeld");
    public static global_klarmeldChosenKompetencer = () => getTranslation("global_klarmeldChosenKompetencer", "Klarmeld valgte kompetencer");
    public static global_klarmeldChosenKompetence = () => getTranslation("global_klarmeldChosenKompetence", "Klarmeld valgt kompetence");
    public static global_godkend = () => getTranslation("global_godkend", "Godkend");
    public static global_godkendt = () => getTranslation("global_godkendt", "Godkendt");
    public static global_godkendtKommentar = () => getTranslation("global_godkendtKommentar", "Godkendt kommentar");
    public static global_kommentarTilGodkendelse = () => getTranslation("global_kommentarTilGodkendelse", "Kommentar til godkendelse");
    public static global_godkendtandet = () => getTranslation("global_godkendtandet", "Godkendt på andet grundlag");
    public static global_ja = () => getTranslation("global_ja", "Ja");
    public static global_nej = () => getTranslation("global_nej", "Nej");
    public static global_SPEAM = () => getTranslation("global_SPEAM", "SPEAM");
    public static global_mangler = () => getTranslation("global_mangler", "Mangler");
    public static global_manglerAuthorisationsId = () => getTranslation("global_manglerAuthorisationsId", "Mangler authorisations id");
    public static global_klar = () => getTranslation("global_klar", "Klar");
    public static global_klarmeldt = () => getTranslation("global_klarmeldt", "Klarmeldt");
    public static global_ejKlarmeldtGodkendt = () => getTranslation("global_ejKlarmeldtGodkendt", "Ej klarmeldt/godkendt");
    public static global_OK = () => getTranslation("global_OK", "OK");
    public static global_indtastSoegning = () => getTranslation("global_indtastSoegning", "Indtast søgning");
    public static global_laegeForloebOversigtSoegning = () => getTranslation("global_laegeForloebOversigtSoegning", "Søg på lægenavn");
    public static global_stillingForloebOversigtSoegning = () => getTranslation("global_stillingForloebOversigtSoegning", "Søg på stillingsnummer eller læge");
    public static global_advancedForloebOversigtSoegning = () => getTranslation("global_advancedForloebOversigtSoegning", "Søg på læge, fødselsdag, sted, m.m.");
    public static global_advancedForloebOversigtSoegningFullText = () => getTranslation("global_advancedForloebOversigtSoegningFullText", "Søg på læge, fødselsdag, sygehus, afdeling og praksis");
    public static global_stillingAdvancedForloebOversigtSoegning = () => getTranslation("global_stillingAdvancedForloebOversigtSoegning", "Søg på stillingsnummer, sted, m.m.");
    public static global_stillingAdvancedForloebOversigtSoegningFullText = () => getTranslation("global_stillingAdvancedForloebOversigtSoegningFullText", "Søg på stillingsnummer, læge, sygehus, afdeling og praksis");
    public static global_advancedSearchLabel = () => getTranslation("global_advancedSearchLabel", "Udvidet søgning");
    public static global_seOverlappendeForloeb = () => getTranslation("global_seOverlappendeForloeb", "Overlappende");
    public static global_soeg = () => getTranslation("global_soeg", "Søg");
    public static global_afdelinger = () => getTranslation("global_afdelinger", "Afdelinger");
    public static global_specialer = () => getTranslation("global_specialer", "Specialer");
    public static global_vaelgspecialer = () => getTranslation("global_vaelgspecialer", "Vælg specialer");
    public static global_daysLeft = () => getTranslation("global_daysLeft", "Dage tilbage");
    public static global_tidGodkendt = () => getTranslation("global_tidGodkendt", "Tid godkendt");
    public static global_fremtidigtForloeb = () => getTranslation("global_fremtidigtForloeb", "Fremtidigt forløb");
    public static global_pastForloeb = () => getTranslation("global_pastForloeb", "Tidligere forløb");
    public static global_manueltAfsluttet = () => getTranslation("global_manueltAfsluttet", "Manuelt afsluttet");
    public static global_filtrer = () => getTranslation("global_filtrer", "Filtrer");
    public static global_filtrerede = () => getTranslation("global_filtrerede", "Filtrerede ");
    public static global_showing = () => getTranslation("global_showing", "viser");
    public static global_folder = () => getTranslation("global_folder", "Mappe");
    public static global_getData = () => getTranslation("global_getData", "Henter data");
    public static global_fetchingEducationFiles = () => getTranslation("global_fetchingEducationFiles", "Henter uddannelsessted filer");
    public static global_dataOverfoert = () => getTranslation("global_dataOverfoert", "Data overført");
    public static global_forloebHaandteret = () => getTranslation("global_forloebHaandteret", "Forløb håndteret");
    public static global_dataOverfoerselFejlet = () => getTranslation("global_dataOverfoerselFejlet", "Fejl ved dataoverførsel");
    public static global_fejlVedMarkeringAfHaandteret = () => getTranslation("global_fejlVedMarkeringAfHaandteret", "Fejl ved markering af håndteret");
    public static global_subject = () => getTranslation("global_subject", "Emne");
    public static global_content = () => getTranslation("global_content", "Indhold");
    public static global_forrige = () => getTranslation("global_forrige", "Forrige");
    public static global_naeste = () => getTranslation("global_naeste", "Næste");
    public static global_afslut = () => getTranslation("global_afslut", "Afslut");
    public static global_nyheder = () => getTranslation("global_nyheder", "Nyheder");
    public static global_dataadministration = () => getTranslation("global_dataadministration", "Dataadministration");
    public static global_questions = () => getTranslation("global_questions", "Spørgsmål");
    public static global_betingelser = () => getTranslation("global_betingelser", "Betingelser");
    public static global_godkendtAf = () => getTranslation("global_godkendtAf", "Godkendt af");
    public static global_kode = () => getTranslation("global_kode", "Kode");
    public static global_ikkeGodkendt = () => getTranslation("global_ikkeGodkendt", "Ikke godkendt");
    public static global_alleKompetencerErGodkendt = () => getTranslation("global_alleKompetencerErGodkendt", "Alle kompetencer er godkendt");
    public static global_attestationForTidsmaessigtGennemfoertUddannelse = () => getTranslation("global_attestationForTidsmaessigtGennemfoertUddannelse", "Attestation for tidsmæssigt gennemført uddannelse");
    public static global_generelleObligatoriskekurseriIKBUGodkendt = () => getTranslation("global_generelleObligatoriskekurseriIKBUGodkendt", "Generelle obligatoriske kurser i KBU godkendt");
    public static global_KBU = () => getTranslation("global_KBU", "KBU");
    public static global_AnsaettelsesogUddannelsesaftaleUploadet = () => getTranslation("global_AnsaettelsesogUddannelsesaftaleUploadet", "Ansættelses og Uddannelsesaftale uploadet");
    public static global_skemaOverLaegeligBeskaeftigelseUdfyldt  = () => getTranslation("global_skemaOverLaegeligBeskaeftigelseUdfyldt", "Skema over lægelig beskæftigelse udfyldt ");
    public static global_dokumentation  = () => getTranslation("global_dokumentation", "Obligatorisk dokumentation");
    public static global_stpsDokumentation  = () => getTranslation("global_stpsDokumentation", "Dokumentation til STPS");
    public static global_stpsFileLimits  = () => getTranslation("global_stpsFileLimits", "De enkelte filers størrelse må ikke overstige 12 MB og filtypen skal være PDF.");
    public static global_pressForberedDokumentationToUpload  = () => getTranslation("global_pressForberedDokumentationToUpload", "Vælg knappen 'Forbered dokumentation til STPS' for at uploade 'Ansættelses - og uddannelsesaftale'");
    public static global_pressForberedDokumentationToFill  = () => getTranslation("global_pressForberedDokumentationToFill", "Vælg knappen 'Forbered dokumentation til STPS' for at udfylde 'Skema over lægelig beskæftigelse'");
    public static global_ownFiles  = () => getTranslation("global_ownFiles", "Egne vedhæftede filer");
    public static global_feedback  = () => getTranslation("global_feedback", "Feedback");
    public static global_fortsaet  = () => getTranslation("global_fortsaet", "Fortsæt");
    public static global_addCommentGodkend = () => getTranslation("global_addCommentGodkend", "Kommentar til godkendelse");
    public static global_addCommentGodkendLabel = () => getTranslation("global_addCommentGodkend", "Indtast kommentar til godkendelse");
    public static global_addCommentGodkendOptional = () => getTranslation("global_addCommentGodkendOptional", "Eventuel kommentar til godkendelse");
    public static global_postnummer = () => getTranslation("global_addCommentGodkend", "Postnummer ");
    public static global_address  = () => getTranslation("global_addCommentGodkend", "Adresse");
    public static global_noPostnummer = () => getTranslation("global_addCommentGodkend", "Ingen postnummer ");
    public static global_noAddress  = () => getTranslation("global_addCommentGodkend", "Ingen addresse");
    public static global_userAddressUpdated = () => getTranslation("global_userWorkPhoneNumberUpdated", "Brugerens adressse blev opdateret");
    public static global_userZipCodeUpdated = () => getTranslation("global_userWorkPhoneNumberUpdated", "Brugerens postnummer blev opdateret");
    public static global_chooseMultiple = () => getTranslation("global_chooseMultiple", "Vælg flere");
    public static global_forloebMergeField = () => getTranslation("global_forloebMergeField", "Forløb flettefelt");
    public static global_tekstMergeField = () => getTranslation("global_tekstMergeField", "Tekst flettefelt");
    public static global_chooseMergeField = () => getTranslation("global_chooseMergeField", "Vælg flettefelt");
    public static global_insertMergeField = () => getTranslation("global_insertMergeField", "Indsæt flettefelt");
    public static global_inactive = () => getTranslation("global_inactive", "Inaktive");
    public static global_national = () => getTranslation("global_national", "National");
    public static global_regional= () => getTranslation("global_regional", "Regional");
    public static global_aar = () => getTranslation("global_aar", "År");
    public static global_minutter = () => getTranslation("global_minutter", "Minutter");
    public static global_date = () => getTranslation("global_date", "Dato");
    public static global_period = () => getTranslation("global_period", "Periode");
    public static global_from = () => getTranslation("global_from", "Fra");
    public static global_to = () => getTranslation("global_to", "Til");
    public static global_hoveduddannelse  = () => getTranslation("global_hoveduddannelse", "Hoveduddannelse");
    public static global_faellesInternMedicin  = () => getTranslation("global_faellesInternMedicin", "Fælles intern medicin");
    public static global_specialespecifikke  = () => getTranslation("global_specialespecifikke", "Specialespecifikke");
    public static global_grundkursusIPsykoterapi  = () => getTranslation("global_grundkursusIPsykoterapi", "Grundkursus i Psykoterapi");
    public static global_forskningstraening  = () => getTranslation("global_forskningstraening", "Forskningstræning");
    public static global_forside = () => getTranslation("global_forside", "Forside");
    public static global_hjaelp = () => getTranslation("global_hjaelp", "Hjælp");
    public static global_kontakt = () => getTranslation("global_kontakt", "Kontakt");
    public static global_logning = () => getTranslation("global_logning", "Logning");
    public static global_forloebGodkendt = () => getTranslation("global_forloebGodkendt", "Forløb godkendt");
    public static global_cookies = () => getTranslation("global_cookies", "Cookies");
    public static global_stillingstype = () => getTranslation("global_stillingstype", "Stillingstype");
    public static global_luk = () => getTranslation("global_luk", "Luk");
    public static global_til = () => getTranslation("global_til", "til");
    public static global_af = () => getTranslation("global_af", "af");
    public static global_uddannelsestrin = () => getTranslation("global_uddannelsestrin", "Uddannelsestrin");
    public static global_brugerroller = () => getTranslation("global_brugerroller", "Roller");
    public static global_rolle = () => getTranslation("global_rolle", "Rolle");
    public static global_vaelgRoller = () => getTranslation("global_vaelgRoller", "Vælg roller");
    public static global_praksis = () => getTranslation("global_praksis", "Praksis");
    public static global_sygehuse = () => getTranslation("global_sygehuse", "Sygehuse");
    public static global_sygehus = () => getTranslation("global_sygehus", "Sygehus");
    public static global_vejledertilknytninger = () => getTranslation("global_vejledertilknytninger", "Vejledertilknytninger");
    public static global_laeger = () => getTranslation("global_laeger", "Læger");
    public static global_adgangudloeber = () => getTranslation("global_adgangudloeber", "Adgang udløber");
    public static global_tilknytningudloeber = () => getTranslation("global_tilknytningudloeber", "Tilknytning udløber");
    public static global_tidsbegraensedeforloebadgange = () => getTranslation("global_tidsbegraensedeforloebadgange", "Tidsbegrænsede adgange til forløb");
    public static global_ubegraenset = () => getTranslation("global_ubegraenset", "Ubegrænset");
    public static global_tegn = () => getTranslation("global_tegn", "tegn");
    public static global_valgte = () => getTranslation("global_valgte", "valgte");
    public static global_print = () => getTranslation("global_print", "Print");
    public static global_createdBy = (createdByName="") => getTranslation("global_createdBy", `Oprettet af ${createdByName}`);
    public static global_vis = () => getTranslation("global_vis", "Vis");
    public static global_notifikationer = () => getTranslation("global_notifikationer", "Notifikationer");
    public static global_historik = () => getTranslation("global_historik", "Historik");
    public static global_indstillinger = () => getTranslation("global_indstillinger", "Indstillinger");
    public static global_uploadFile = () => getTranslation("global_uploadFile", "Upload fil");
    public static global_deleteFileError = () => getTranslation("global_deleteFileError", "Sletning af fil fejlede");
    public static global_deleteFileNotAllowedError = () => getTranslation("global_deleteFileNotAllowedError", "Ingen adgang til at slette denne fil");
    public static global_editFileNotAllowedError = () => getTranslation("global_editFileNotAllowedError", "Ingen adgang til at redigere denne fil");
    public static global_deleteFileSuccess = () => getTranslation("global_deleteFileSuccess", "Filen er slettet");
    public static global_updateFileError = () => getTranslation("global_updateFileError", "Opdatering af fil fejlede");
    public static global_updateFileSuccess = () => getTranslation("global_updateFileSuccess", "Opdateret fil");
    public static global_visibility = () => getTranslation("global_visibility", "Synlighed");
    public static global_wholeDenmark = () => getTranslation("global_wholeDenmark", "Hele Danmark");
    public static global_mine = () => getTranslation("global_mine", "Mine");
    public static global_remarks = () => getTranslation("global_remarks", "Bemærkninger");
    public static global_ikkeAngivet = () => getTranslation("global_ikkeAngivet", "Ikke angivet");
    public static global_none = () => getTranslation("global_none", "Ingen");
    public static global_vaelgAlle = () => getTranslation("global_vaelgAlle", "Vælg alle");

}
