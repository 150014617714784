import {ReactComponent as Logo} from "../../content/icons/logo-icon.svg"
import {Localizer} from "../../infrastructure/localization/localizer";
import {Spinner} from "../../core/components/spinner/spinner";
import {RoutePaths} from "../../infrastructure/routes";
import {AccountService} from "../../services/account/accountService";
import { useEffect, useState } from "react";
import useUser from "core/hooks/useUser";
import BodyContainer from "core/layout/bodyContainer";

const accountService = new AccountService();

export const AuthPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { currentUser } = useUser();

    useEffect(() => {
        const handleWaitForSignIn = () => {
            setIsLoading(true);
            accountService.WaitForSignIn().then((user) => {
                if(!user.profile.authenticated_user_id)
                {
                    window.top?.location.replace(RoutePaths.Impersonate.path);
                } else {
                    accountService.setUser().then(() => {
                        window.location.replace(RoutePaths.Index.path);
                    })
                }
            }).catch(x => {
                // ignore error that occurs in development, because the SPA is running in http.

                if (currentUser.Authenticated) {
                    // Solution to "No matching state found in storage"
                    setIsLoading(false);
                    window.location.replace(RoutePaths.Index.path);
                }
            });
        }
        handleWaitForSignIn();
    }, [currentUser.Authenticated]);

    
    return <>
        <BodyContainer>
            <nav className="navbar navbar-default bg-primary fixed-top public-top-menu padding-0">
                <div className="container padding-0" >
                    <a className="flex-container-row flex-align-c flex-align-l">
                        <Logo/>
                        <h5>
                            {Localizer.global_uddannelseslaegedk()}
                        </h5>
                    </a>
                </div>
            </nav>
            <div className="page-content">
                <div className="main-content">
                    <div className="body-content">
                        {isLoading && <Spinner text={Localizer.loginIn()}/>}
                    </div>
                </div>
            </div>
        </BodyContainer>
    </>
}
