import {ApiResponse} from "../api/apiResponse";
import {AccountService} from "../account/accountService";
import Cookies from "js-cookie";
import {CookieConstants} from "../../infrastructure/cookieConstants";
import {PromiseWithCancel} from "./promiseWithCancel";
import {Localizer} from "../../infrastructure/localization/localizer";

export class HttpUnauthorizedError implements Error {
    name: string = "UnauthorizedError";
    message: string = "User is unauthorized";
    stack?: string;
}

export class HttpResponseError implements Error {
    constructor(message: string, status?: number) {
        this.message = message;
        this.status = status;
    }

    name: string = "HttpResponseError";
    message: string;
    status?: number;
    stack?: string;
}

export class HttpClientService {
    async Get<T>(url: string | URL): Promise<T> {
        let headers = await this.GetDefaultHeaders();
        const response = await fetch(url.toString(), {
            method: "get",
            headers,
        });
        await this.handleResponse(response);
        return await response.json();
    }

    GetWithCancellation<T>(url: string | URL) {
        const controller = new AbortController();
        const signal = controller.signal;
        const promise = new Promise(async (resolve, reject) => {
            try {
                let headers = await this.GetDefaultHeaders();
                const response = await fetch(url.toString(), {
                    method: "get",
                    headers,
                    signal: signal
                });
                await this.handleResponse(response);
                resolve(response.json())
            }
            catch (ex:unknown) {
                if (this.isAbortError(ex)) {
                    console.log(ex.message);
                } else {
                    //Reject promise, so that we can listen down the stack on "catch"
                    reject(ex);
                }
            }
        });

        (promise as PromiseWithCancel<T>).cancel = () => controller.abort();
        return promise as PromiseWithCancel<T>;
    }

    async Post<T, TData>(url: string | URL, data: TData, postWithAsyncValidation?: boolean): Promise<T> {
        let headers = await this.GetDefaultHeaders();
        headers.append('Content-Type', 'application/json');

        const body = JSON.stringify(data);
        const response = await fetch(url.toString(), {
            method: "post",
            headers,
            body: body
        });

        await this.handleResponse(response, postWithAsyncValidation);
        return await response.json();
    }

    async Patch<T, TData>(url: string | URL, data: TData, postWithAsyncValidation?: boolean): Promise<T> {
        let headers = await this.GetDefaultHeaders();
        headers.append('Content-Type', 'application/json');

        const body = JSON.stringify(data);
        const response = await fetch(url.toString(), {
            method: "PATCH",
            headers,
            body: body
        });

        await this.handleResponse(response, postWithAsyncValidation);
        return await response.json();
    }

    async UploadFormdata<T>(url: string, formData: FormData): Promise<T> {
        let headers = await this.GetDefaultHeaders();
        //Det er med vilje der ikke sættes en Content-Type!

        const response = await fetch(url, {
            method: "post",
            headers,
            body: formData
        });

        await this.handleResponse(response);
        return await response.json();
    }

    async DownloadFile(url: string, contentType: string): Promise<Blob> {
        let headers = await this.GetDefaultHeaders();
        headers.append('Content-Type', contentType);

        const response = await fetch(url, {
            method: "get",
            headers
        });
        await this.handleResponse(response);
        return await response.blob();
    }

    async DownloadFileWithBody<T>(url: string, body: T, contentType: string): Promise<Blob> {
        let headers = await this.GetDefaultHeaders();
        headers.append('Content-Type', contentType);

        const response = await fetch(url, {
            method: "post",
            headers,
            body: JSON.stringify(body)
        });
        await this.handleResponse(response);
        return await response.blob();
    }

    async DeleteWithBody<T, TData>(url: string, data: TData): Promise<T> {
        let headers = await this.GetDefaultHeaders();
        headers.append('Content-Type', 'application/json');

        const body = JSON.stringify(data);
        const response = await fetch(url.toString(), {
            method: "delete",
            headers,
            body: body
        });

        await this.handleResponse(response);
        return await response.json();
    }

    async Delete<T>(url: string | URL): Promise<T> {
        let headers = await this.GetDefaultHeaders();

        const response = await fetch(url.toString(), {
            method: "delete",
            headers
        });

        await this.handleResponse(response);
        return await response.json();
    }

    public GetDefaultHeaders = async (): Promise<Headers> => {
        const accountService = new AccountService();

        const token = await accountService.getAccesToken();
        let headers = new Headers();

        if (token) {
            headers.append('pragma', 'no-cache');
            headers.append('Authorization', 'bearer ' + token);
        }
        else {
            const tokenFromCookie = Cookies.get(CookieConstants.tokenInCookieKey);
            if (tokenFromCookie) {
                headers.append('pragma', 'no-cache');
                headers.append('Authorization', 'bearer ' + tokenFromCookie);
            }
        }

        return headers;
    }

    public GetPlainDefaultHeaders = async (): Promise<any> => {
        let headers: any;
        headers =
        {
            'pragma': 'no-cache',
        }

        return headers;
    }

    public BuildUrlWithSearchParams<T extends object>(url: string, model: T) {
        const urlWithSearchParams = new URL(url);
        Object.entries(model).map(([key, value]) => urlWithSearchParams.searchParams.append(key, value));
        return urlWithSearchParams;
    }

    private isAbortError(error: any): error is DOMException {
        return !!(error && error.name === "AbortError");

    }

    private async handleResponse(response: Response, postWithAsyncValidation?: boolean) {
        if (response.status === 401) {
            throw new HttpUnauthorizedError();
        }
        if (!response.ok) {
            if (response.status === 500) {
                throw new Error(Localizer.global_internalServerError());
            }
            if (response.status === 400 && postWithAsyncValidation) {
                //Grundet async felter, har vi taget beslutning om, at fejl 400 (BadRequest) bruges til validering. Derfor throwes der ikke en ResponseError
            }
            else {
                const apiReponse: ApiResponse<any> = await response.json()
                throw new HttpResponseError(apiReponse.apiResponseMessage.errorMessage, response.status);
            }
        }
    }

    async DownloadQuickguideFile(url: string, contentType: string): Promise<Blob> {
        let headers = await this.GetDefaultHeaders();
        headers.append('Content-Type', contentType);

        const response = await fetch(url, {
            method: "get",
            headers
        });

        await this.handleResponse(response);
        return await response.blob();
    }

    async DownloadSpoergerammeFile(url: string): Promise<Blob> {
        let headers = await this.GetDefaultHeaders();

        const response = await fetch(url, {
            method: "get",
            headers
        });

        await this.handleResponse(response);
        return await response.blob();
    }
}
