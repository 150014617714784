import {
    RolleEnum,
    UserRoleIsAdmin,
    UserRoleIsHrMedarbejder,
    UserRoleIsKonsulentForLaegeligVidereuddannelse,
    UserRoleIsKursusBruger,
    UserRoleIsKursusleder,
    UserRoleIsKursusudbyder,
    UserRoleIsLaege, UserRoleIsLedelse,
    UserRoleIsUddannelsesansvarlig, UserRoleIsSekretaer,
    UserRoleIsVejleder,
    UserRoleIsVusMedarbejeder,
    UserRoleIsForskningstraeningsvejleder
} from "../../infrastructure/enums/userRole";
import {RolleTitelEnum} from "../../infrastructure/enums/rolleTitelEnum";
import { RolleTitelDto } from "./rolleTitelDto";

export class UserModel {
    public UserId: string
    public AuthorizationId: string
    public AuthenticatedUserId: string
    public AuthenticatedAuthorizationId: string
    public Authenticated: boolean;
    public CurrentRolle: RolleEnum;
    public CurrentRolleTitel: RolleTitelEnum;
    public RolesAndTitles: RolleTitelDto[];
    public Name: string;
    public Initialer: string;
    public ProfileImageId?: string;
    public Email: string;

    public HasRole(userRoles : Array<RolleEnum> | null) : boolean {
        if (userRoles == null) {
            return true;
        }

        return userRoles.includes(this.CurrentRolle);
    }

    public IsAdmin() : boolean {
        return UserRoleIsAdmin(this.CurrentRolle);
    }

    public IsLaege() : boolean {
        return UserRoleIsLaege(this.CurrentRolle);
    }

    public IsKursusleder() : boolean {
        return UserRoleIsKursusleder(this.CurrentRolle);
    }

    public IsKursusBruger() : boolean {
        return UserRoleIsKursusBruger(this.CurrentRolle);
    }

    public IsKursusudbyder() : boolean {
        return UserRoleIsKursusudbyder(this.CurrentRolle);
    }

    public IsHrMedarbejder() : boolean {
        return UserRoleIsHrMedarbejder(this.CurrentRolle);
    }

    public IsLedelse() : boolean {
        return UserRoleIsLedelse(this.CurrentRolle);
    }

    public IsSekretaer() : boolean {
        return UserRoleIsSekretaer(this.CurrentRolle);
    }

    public IsUddannelsesansvarlig() : boolean {
        return UserRoleIsUddannelsesansvarlig(this.CurrentRolle);
    }

    public IsVusMedarbejeder() : boolean {
        return UserRoleIsVusMedarbejeder(this.CurrentRolle);
    }

    public IsVejleder() : boolean {
        return UserRoleIsVejleder(this.CurrentRolle);
    }

    public IsKonsulentForLaegeligVidereuddannelse() : boolean {
        return UserRoleIsKonsulentForLaegeligVidereuddannelse(this.CurrentRolle);
    }

    public IsForskningstraeningsvejleder() : boolean {
        return UserRoleIsForskningstraeningsvejleder(this.CurrentRolle);
    }

    public IsAdminOrVus() : boolean {
        return this.IsAdmin() || this.IsVusMedarbejeder();
    }

    public CanPlanlaeggeForloeb() : boolean {
        return this.IsAdmin() || this.IsVusMedarbejeder() || this.IsLaege();
    }

    public CanEditForloeb() : boolean {
        return this.IsAdmin() || this.IsLaege() || this.IsVusMedarbejeder();
    }

    public CanDeleteForloeb() : boolean {
        return this.IsAdmin() || this.IsHrMedarbejder() || this.IsVusMedarbejeder();
    }

    public CanAfslutteForloebManuelt() : boolean {
        return this.IsAdmin() || this.IsVusMedarbejeder();
    }

    public canUseSavedFilters() : boolean {
        return !this.IsLaege() && !this.IsVejleder();
    }

    public canSeeFileAdminPage() : boolean {
        return this.IsAdminOrVus() || this.IsLedelse() || this.IsKonsulentForLaegeligVidereuddannelse() || this.IsUddannelsesansvarlig() || this.IsSekretaer();
    }

    public static CreateUser(userModel: UserModel) : UserModel {
        let newUserModel = new UserModel();
        newUserModel.Authenticated = userModel.Authenticated;
        newUserModel.AuthenticatedUserId = userModel.AuthenticatedUserId;
        newUserModel.AuthenticatedAuthorizationId = userModel.AuthenticatedAuthorizationId;
        newUserModel.AuthorizationId = userModel.AuthorizationId;
        newUserModel.Name = userModel.Name;
        newUserModel.UserId = userModel.UserId;
        newUserModel.Initialer = userModel.Initialer;
        newUserModel.Email = userModel.Email;
        newUserModel.CurrentRolle = userModel.CurrentRolle;
        newUserModel.CurrentRolleTitel = userModel.CurrentRolleTitel;
        newUserModel.RolesAndTitles = userModel.RolesAndTitles;
        newUserModel.ProfileImageId = userModel.ProfileImageId;
        return newUserModel;
    }

    public static CreateNotAuthenticatedUser() : UserModel {
        const userModel = new UserModel();
        userModel.Authenticated = false;
        return userModel;
    }

    public hasRolleTitel(titel: RolleTitelEnum) : boolean {
        return this.CurrentRolleTitel === titel;
    }

}
