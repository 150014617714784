import {useEffect, useState} from "react";
import {SetTitleBar} from "../../core/layout/titleBar/titleBarApi";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../core/layout/responsive";
import {Localizer} from "infrastructure/localization/localizer";
import {kompetenceCardReducer, KompetenceCardState, setActiveKlarmeldingPageTab} from "pages/kompetencerPage/cards/kompetenceCardSlice";
import {KompetenceCardList, KompetenceCardListType} from "pages/kompetencerPage/cards/kompetenceCardList";
import {KompetenceOverview} from "pages/kompetencerPage/details/kompetenceOverview";
import { Tab, Tabs, isActiveTab } from "core/components/Tabs/Tabs";
import { ForloebMedAttestationForTidKlarTilGodkendelse } from "./forloebAttestationForTidKlar";
import useUser from "../../core/hooks/useUser";
import {UddannelsesplanerKlarmeldt} from "./uddannelsesplanerKlarmeldt";
import useRefreshablePage from "core/infrastructure/routing/hooks/useRefreshablePage";
import React from "react";
import {VejledersamtalerKlarmeldt} from "./vejledersamtalerKlarmeldt";

export const KlarmeldingerPageTabIds = {
    kompentencerTabContentId: "KompentencerTabContentId",
    vejledersamtalerTabContentId: "VejledersamtalerTabContentId",
    attestationForTidKlarTilGodkendelseId: "AttestationForTidKlarTilGodkendelseId",
    uddannelsesplanerTabContentId: "UddannelsesplanerTabContentId"
} as const;

export function KlarmeldingerPage() {
    const { pageKey } = useRefreshablePage();
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const dispatch = useAppDispatch();
    const { currentUser } = useUser();

    const [tabs, setTabs] = useState<Tab[]>([]);

    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;

    const currentUserIsVejleder = currentUser.IsVejleder();

    SetTitleBar(Localizer.global_klarmeldinger(),
        [{text: Localizer.global_klarmeldinger(), url: ""}]);

    useEffect(() => {

        const kompentencerTabContent = (
            <div className="card top-level-container">
                <div className="card-header">
                    <h3 className="card-title">{Localizer.global_kompetencer()}</h3>
                </div>
                <div className={"row no-gutters" + (!IsMobile ? " height-85-vh" : "")}>
                    {!IsMobile &&
                        <>
                            <div className="border-right col-5 card-list-custom column-height">
                                <KompetenceCardList
                                    kompetenceListType={KompetenceCardListType.KlarmeldteKompetencerByVejleder}
                                    customModileSearchPlaceholder={Localizer.kompetencePage_searchStringMobile()}
                                    customSearchPlaceholder={Localizer.kompetencePage_searchString()}
                                    disableFileCount
                                />
                            </div>

                            <div className="col-7 h-100 overflow-auto">
                                {!kompetenceCardState.isLoadingList &&
                                    <KompetenceOverview getAllRegardlessOfMaalbeskrivelse={true}/>
                                }
                            </div>
                        </>
                    }

                    {IsMobile &&
                        <div className="col-12">
                            <KompetenceCardList kompetenceListType={KompetenceCardListType.KlarmeldteKompetencerByVejleder} getAllRegardlessOfMaalbeskrivelse={true} disableFileCount/>
                        </div>
                    }
                </div>
            </div>
        );

        setTabs([
            {
                title: Localizer.global_kompetencer(),
                id: KlarmeldingerPageTabIds.kompentencerTabContentId,
                content: kompentencerTabContent,
                defaultActive: isActiveTab(KlarmeldingerPageTabIds.kompentencerTabContentId, kompetenceCardState.activeKlarmeldingPageTabId) ?? true,
            },{
                title: Localizer.forloebpage_godkendTid(),
                id: KlarmeldingerPageTabIds.attestationForTidKlarTilGodkendelseId,
                content: <ForloebMedAttestationForTidKlarTilGodkendelse />,
                defaultActive: isActiveTab(KlarmeldingerPageTabIds.attestationForTidKlarTilGodkendelseId, kompetenceCardState.activeKlarmeldingPageTabId) ?? false,
                hide: currentUserIsVejleder
            }, {
                title: Localizer.global_vejledersamtaler(),
                id: KlarmeldingerPageTabIds.vejledersamtalerTabContentId,
                content: <VejledersamtalerKlarmeldt/>,
                defaultActive: isActiveTab(KlarmeldingerPageTabIds.vejledersamtalerTabContentId, kompetenceCardState.activeKlarmeldingPageTabId) ?? true,
            }, {
                title: Localizer.global_uddannelsesplaner(),
                id: KlarmeldingerPageTabIds.uddannelsesplanerTabContentId,
                content: <UddannelsesplanerKlarmeldt/>,
                defaultActive: isActiveTab(KlarmeldingerPageTabIds.uddannelsesplanerTabContentId, kompetenceCardState.activeKlarmeldingPageTabId) ?? true,
            }
        ]);

    }, [IsMobile, currentUserIsVejleder, kompetenceCardState.activeKlarmeldingPageTabId, kompetenceCardState.activeLaegekompetenceMeta, kompetenceCardState.isLoadingList])

    return (
        <React.Fragment key={pageKey}>
            {kompetenceCardState.activeLaegekompetenceMeta &&
                <Tabs
                    tabs={tabs}
                    setActiveCallback={(activeTabId) => dispatch(setActiveKlarmeldingPageTab(activeTabId))}
                />
            }
        </React.Fragment>
    );
}
