import {UserProfileAvatarSizeEnum} from "./userProfileAvatar";
import {ReactComponent as AgentSVG} from "../../../content/icons/agent.svg";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {EmblaIcons} from "../../emblaIcons";
import React, {useState} from "react";
import classNames from "classnames";
import ImageWithAuth from "./imageWithAuth";
import {FileUploader} from "../fileUpload/fileUploader";
import {FileArchiveEnum} from "../../sharedmodels/fileMetaData/fileArchiveEnum";
import {ReactFileUploaderFile} from "../fileUpload/reactFileUploader";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import './user-profile.scss';

const resolveSizeClassName = (size?: UserProfileAvatarSizeEnum) => {
    switch (size) {
        case UserProfileAvatarSizeEnum.large:
            return "";
        case UserProfileAvatarSizeEnum.medium:
            return "medium";
        case UserProfileAvatarSizeEnum.small:
            return "small";
        case UserProfileAvatarSizeEnum.extraSmall:
            return "extra-small";
        case UserProfileAvatarSizeEnum.extraExstraSmall:
            return "extra-extra-small";
        default:
            return "";
    }
}

const getEmblaIconSize = (size?: UserProfileAvatarSizeEnum) => {
    switch (size) {
        case UserProfileAvatarSizeEnum.large:
        case UserProfileAvatarSizeEnum.medium:
        case UserProfileAvatarSizeEnum.small:
            return "";
        case UserProfileAvatarSizeEnum.extraSmall:
        case UserProfileAvatarSizeEnum.extraExstraSmall:
            return "small";
        default:
            return "";
    }
}

type AvatarBadgeProps = {
    initials?: string;
    size?: UserProfileAvatarSizeEnum;
    colorClassName?: string;
    isImpersonating?: boolean;
    isCurrentUser: boolean;
    profileImageFileMetadataId?: string;
    profileImageFileData?: Uint8Array[];
    fileUploadedCallback?: (file: ReactFileUploaderFile) => void;
    deleteImageCallback?: () => void;
}

const userProfileAvatarClassName = "user-profile-avatar";

export const AvatarBadge = ({
                                initials,
                                size,
                                colorClassName,
                                isImpersonating,
                                profileImageFileMetadataId,
                                profileImageFileData,
                                fileUploadedCallback,
                                deleteImageCallback,
                                isCurrentUser = false
                            }: AvatarBadgeProps) => {

    const sizeClass = resolveSizeClassName(size);
    const emblaIconSizeClass = getEmblaIconSize(size);
    const [isHovered, setIsHovered] = useState(false);

    const [showProfileImage, setShowProfileImage] = useState<boolean>(!!profileImageFileMetadataId);
    const [showInitials, setShowInitials] = useState<boolean>(!profileImageFileMetadataId && !!initials);
    const [showDefaultUserImage, setShowDefaultUserImage] = useState<boolean>(!profileImageFileMetadataId && !initials);

    const showOnHover = isHovered && isCurrentUser;

    const ImageFetchFailed = () => {
        if (initials)
            setShowInitials(true);
        else
            setShowDefaultUserImage(true);
        setShowProfileImage(false);
    }

    return (
        <div
            className={classNames(userProfileAvatarClassName, sizeClass, (!showProfileImage || isImpersonating) && colorClassName)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            {isImpersonating && <AgentSVG/>}
            {!isImpersonating &&
                <>
                    {profileImageFileMetadataId && showProfileImage &&
                        <div
                            className={classNames("square-container rounded-circle", userProfileAvatarClassName, emblaIconSizeClass, emblaIconSizeClass, sizeClass)}>
                            <ImageWithAuth profileImageFileMetadataId={profileImageFileMetadataId}
                                           profileImageData={profileImageFileData}
                                           deleteImageCallback={deleteImageCallback}
                                           showDelete={showOnHover}
                                           imageFetchFailed={ImageFetchFailed}/>
                        </div>
                    }
                    {showInitials &&
                        <p className="profile-initials">{initials}</p>
                    }
                    {showDefaultUserImage &&
                        <EmblaIcon iconName={EmblaIcons.User}
                                   additionalClasses={`${emblaIconSizeClass}`}
                                   wrapInSpan={false}/>
                    }
                    {(!profileImageFileMetadataId && fileUploadedCallback && isCurrentUser) &&
                        <div
                            className={classNames("flex-row-center image-upload-type-icon", profileImageFileMetadataId && "margin-bottom-s")}>
                            <FileUploader
                                additionalLabelClasses={"image-background rounded-circle image-upload-type-icon-hover"}
                                hideText={true}
                                fileType={FileArchiveEnum.ProfileImage}
                                overrideIcon={EmblaIcons.Camera}
                                filesUploadedCallback={(file: ReactFileUploaderFile[]) => fileUploadedCallback(file[0])}
                                fileLocation={FileLocationEnum.Stamdata}
                            />
                        </div>

                    }
                </>
            }
        </div>
    );
}
