import {LaegeStillingResponseModel} from "../../../services/api/logbogStilling/laegeStillingResponseModel";
import {toDate} from "../../../index";
import {UddannelseTypeEnum} from "./uddannelseTypeEnum";
import StringBuilder from "../stringBuilder/stringBuilder";
import { appendGenerelForloebsoverviewPillData } from "../stringBuilder/customFunctions/appendGenerelForloebsoverviewPillData";
import {OverlapCompareModel} from "../OverlapCompareModel";

export class LaegeStillingModel implements OverlapCompareModel{

    public static FromResponseModel(responseModel: LaegeStillingResponseModel): LaegeStillingModel {
        const model = new LaegeStillingModel();
        model.userId = responseModel.userId;
        model.profileImageId = responseModel.profileImageId;
        model.forloebId = responseModel.forloebId;
        model.stillingId = responseModel.stillingId;
        model.laegeNavn = responseModel.laegeNavn;
        model.initialer = responseModel.initialer;
        model.uddannelsesstedId = responseModel.uddannelsesstedId;
        model.uddannelseType = responseModel.uddannelseType;
        model.specialeNavn = responseModel.specialeNavn;
        model.startDate = toDate(responseModel.startDato);
        model.endDate = toDate(responseModel.slutDato);

        model.compareId = responseModel.stillingId;

        model.regionNavn = responseModel.regionNavn;
        model.stillingNummer = responseModel.stillingNummer;
        model.stedInfo = responseModel.stedInfo;
        model.irrelevant = responseModel.irrelevant;
        model.evalueretAt = responseModel.evalueretAt ? toDate(responseModel.evalueretAt) : undefined;
        model.godkendtAt = responseModel.godkendtAt ? toDate(responseModel.godkendtAt) : undefined;
        return model;
    }

    userId: string;
    profileImageId: string;
    stillingId: string;
    forloebId: string;
    uddannelsesstedId: string;
    laegeNavn: string;
    initialer: string;
    uddannelseType: UddannelseTypeEnum;
    specialeNavn: string;
    startDate: Date;
    endDate: Date;

    compareId: string;

    regionNavn: string;
    stillingNummer: string;
    stedInfo: string;
    irrelevant: boolean;
    evalueretAt?: Date;
    godkendtAt?: Date;

    public ToDataString = (separator = '<br/>') => {
        const tooltipInfo = new StringBuilder(this.laegeNavn, separator);

        return appendGenerelForloebsoverviewPillData(
            this.regionNavn,
            this.specialeNavn,
            this.stedInfo,
            this.stillingNummer,
            this.uddannelseType,
            this.startDate,
            this.endDate,
            this.irrelevant,
            this.evalueretAt,
            this.godkendtAt,
            tooltipInfo
        ).toString();
    }
}

