import React, {createContext, ReactNode, useCallback, useContext, useEffect, useReducer, useState} from "react";
import {UserModel} from "../../../services/account/userModel";
import {AccountService} from "../../../services/account/accountService";
import {StorageKeys} from "../../../infrastructure/storage/storageKeys";
import { BrugerIndstillingModel } from "core/sharedmodels/brugerIndstilling/brugerIndstillingModel";
import useStamdataApi from "core/hooks/useStamdataApi";
import { BrugerIndstillingTypeKeyEnum } from "core/sharedmodels/brugerIndstilling/brugerIndstillingTypeKeyEnum";

const accountService = new AccountService();
const StorageEventType = "storage";

type UserContextType = {
    user: UserModel;
    settings: BrugerIndstillingModel[];
}

const UserContext = createContext<{
    currentUser: UserContextType,
    settingsLoading: boolean,
    setUser: (payload: UserModel) => void,
    setUserSettings: (payload: BrugerIndstillingModel[]) => void,
    setUserSetting: (key: BrugerIndstillingTypeKeyEnum, value: string) => void,
} | undefined>(undefined);

enum UserReducerActionType {
    SetUser,
    SetSettings,
}

const userReducer = (
    currentUser: UserContextType,
    action: { type: UserReducerActionType, payload: Partial<UserContextType> }
): UserContextType => {
    switch (action.type) {
        case UserReducerActionType.SetUser:
            return {
                ...currentUser,
                user: action.payload.user ?? currentUser.user
            }
        case UserReducerActionType.SetSettings:
            return {
                ...currentUser,
                settings: action.payload.settings ?? currentUser.settings
            }
    }
}

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) throw new Error("useUserContext must be used in UserContextProvider");
    return context;
};

type UserContextProviderProps = { children?: ReactNode; }

const UserContextProvider = ({children}: UserContextProviderProps) => {
    const [currentUser, dispatch] = useReducer(userReducer, {user: accountService.getUser(), settings: []});
    const { stamdataBrugerIndstillingApi } = useStamdataApi();
    const [ settingsLoading, setSettingsLoading ] = useState(true);

    const setUser = useCallback((payload: UserModel) => {
        dispatch({
            type: UserReducerActionType.SetUser,
            payload: { user: UserModel.CreateUser(payload) }
        });
    }, []);

    const setUserSettings = useCallback((payload: BrugerIndstillingModel[]) => {
        dispatch({
            type: UserReducerActionType.SetSettings,
            payload: { settings: payload }
        });
    }, []);

    const setUserSetting = useCallback((key: BrugerIndstillingTypeKeyEnum, value: string) => {
        dispatch({
            type: UserReducerActionType.SetSettings,
            payload: { settings: [ ...currentUser.settings.filter(x => x.typeKey !== key), { typeKey: key, value: value } ] }
        });
    }, [currentUser.settings]);

    useEffect(() => {
        const onLocalStorageUserUpdatedListener = (ev: StorageEvent) => {
            if (ev.key === StorageKeys.user) {
                const updatedUser = accountService.getUser();
                setUser(updatedUser);
            }
        }

        window.addEventListener(StorageEventType, onLocalStorageUserUpdatedListener);
        return () => window.removeEventListener(StorageEventType, onLocalStorageUserUpdatedListener);
    }, [currentUser.user.UserId, setUser]);

    useEffect(() => {
        const fetchSettings = async () => {
            const userIsAuthenticated = currentUser.user.Authenticated && accountService.getUser().Authenticated;
            if (userIsAuthenticated && currentUser.user.UserId) {
                
                setSettingsLoading(true);
                stamdataBrugerIndstillingApi
                    .GetBrugerIndstillinger()
                    .then(result => setUserSettings(result))
                    .finally(() => setSettingsLoading(false));
            }
        }
        fetchSettings();
    }, [currentUser.user.Authenticated, currentUser.user.UserId, setUserSettings, stamdataBrugerIndstillingApi]);

    return (
        <UserContext.Provider
            value={{
                    currentUser: currentUser,
                    settingsLoading,
                    setUser,
                    setUserSettings,
                    setUserSetting
                }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
