import React, {useCallback, useRef, useState} from "react";
import {Localizer} from "infrastructure/localization/localizer";
import {SlideIn} from "../../core/components/slideIn/slideIn";
import {VejlederCrud} from "./components/vejlederCrud";
import {IColumnOrderRequestModel, TableAsync} from "../../core/components/table/tableAsync";
import {nameof} from "ts-simple-nameof";
import {VejlederTableResponseModel} from "../../services/api/stamdataVejlederApi/vejlederTableResponseModel";
import {VejlederTableColumnsPostModel} from "../../services/api/stamdataVejlederApi/vejlederTableColumnsPostModel";
import {VejlederEdit} from "./components/vejlederEdit";
import {dateTimeStringFromApiToDate} from "../../index";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {setValidated, ValidatedDateReducer, VejledereValidate} from "./vejledereValidateDateSlice";
import {DataTableHeaderAction} from "../../core/components/table/DataTableHeaderAction";
import {ModalSize} from "../../core/components/slideIn/modalSize";
import './vejlederePage.scss';
import { NotificationModule } from "ditmer-embla";
import { EmblaIcons } from "core/emblaIcons";
import DynamicButton from "core/components/button/dynamicButton";
import VejlederEditFooter from "./components/vejlederEditFooter";
import VejlederCrudFooter from "./components/vejlederCrudFooter";
import useUser from "core/hooks/useUser";
import useStamdataApi from "core/hooks/useStamdataApi";
import {AsyncTableReducer, AsyncTableState, setFilter, setTableInitialized} from "pages/bruger/asyncTableSlice";
import useScreenResolution from "core/hooks/useScreenResolution";
import {CheckboxSwitch} from "../../core/components/checkbox/checkboxSwitch";

export function VejlederePage() {
    const dispatch = useAppDispatch();

    const modalTarget = "vejleder-create-modal";
    const modalEditTarget = "vejleder-edit-modal";
    const validateButtonId = "validate-btn";
    const excelButtonId = "excel-btn";
    const createVejlederButtonId = "create-vejleder-btn";
    const showEndedTilknytningerSwitchButtonId = "show-ended-vejlerd-switch-id";

    const [clickedUddannelsesstedTilknytningId, setclickedUddannelsesstedTilknytningId] = useState<string>();
    const [tableNumber, setTableNumber] = useState<number>(0);
    const validatedDate = useAppSelector(ValidatedDateReducer) as VejledereValidate;
    const asyncTableState = useAppSelector(AsyncTableReducer) as AsyncTableState;
    const { currentUser } = useUser();
    const { stamdataVejlederApi, stamdataUserApi } = useStamdataApi();
    const [showVejleder, setShowVejleder] = useState(false);
    const { isMobile } = useScreenResolution();

    const onSwitchClicked = (): void => {
        dispatch(setFilter({
            ShowUdgaaedeVejledertilknytninger: !showVejleder
        }))
        setShowVejleder(!showVejleder);
    };

    const handleClickValidateVejledere = useCallback(async () => {
        await stamdataUserApi.setVejledereValidated(currentUser.UserId);
        dispatch(setValidated(new Date().dateWithoutTimeFormat(false)));
        NotificationModule.showSuccess(Localizer.vejlederValidated(), "");
    }, [currentUser.UserId, dispatch, stamdataUserApi]);

    const handleClickDownloadExcel = useCallback(async () => {
        await stamdataVejlederApi.GetVejlederAfdelingDetailsForExport(asyncTableState.search, asyncTableState.order);
    }, [asyncTableState.order, asyncTableState.search, stamdataVejlederApi]);
    const renderTableActions = () => {
        return (
            <div className="flex-column flex-align-b">

                <div className="flex-end">
                    <div className="flex align-content-center mr-2">
                        <CheckboxSwitch id={showEndedTilknytningerSwitchButtonId}
                                        label={Localizer.ShowUdgaaedeVejledertilknytninger()}
                                        value={showVejleder}
                        />
                    </div>

                    <DynamicButton id={excelButtonId} additionalClasses={'mr-2'} useFlex>
                        <DynamicButton.TableHeaderActionLoadingContainer  />
                        <DynamicButton.TextIconItem iconName={EmblaIcons.Incoming} text={Localizer.GetExcel()}/>
                    </DynamicButton>

                    <DynamicButton id={validateButtonId} additionalClasses={'mr-2'}>
                        <DynamicButton.TextIconItem iconName={EmblaIcons.DocumentCheck} text={Localizer.validateVejleder()}/>
                    </DynamicButton>

                    <DynamicButton id={createVejlederButtonId} additionalClasses={'mr-2'} isPrimary={true} modal={{ dataTarget: "#" + modalTarget}}>
                        <DynamicButton.TextIconItem iconName={EmblaIcons.Plus} text={Localizer.vejlederPage_createVejleder()}/>
                    </DynamicButton>
                </div>

                {validatedDate.validatedDate &&
                    <p className="subtle caption small flex-end">
                        {Localizer.lastvalidated() + " " + validatedDate.validatedDate}
                    </p>
                }
            </div>
        )
    }

    const renderTableHead = () => {
        return (
            <thead>
            <tr>
                <th data-column-name={nameof<VejlederTableColumnsPostModel>(x => x.Navn)}
                    data-priority="1">
                    {Localizer.global_name()}
                </th>
                <th data-column-name={nameof<VejlederTableColumnsPostModel>(x => x.Sygehus)}
                    data-priority="1">
                    {Localizer.sygehus()}
                </th>
                <th data-column-name={nameof<VejlederTableColumnsPostModel>(x => x.Uddannelsessted)}
                    data-priority="1">
                    {Localizer.uddannelsessted()}
                </th>
                <th data-column-name={nameof<VejlederTableColumnsPostModel>(x => x.StartDatoUtc)}
                    data-priority="1">
                    {Localizer.global_periode()}
                </th>
            </tr>
            </thead>
        );
    }

    const vejlederTableColums = (): DataTables.ColumnSettings[] => {
        let columns: DataTables.ColumnSettings[] = [];

        columns = columns.concat([
            {
                data: (data: VejlederTableResponseModel) => data.navn,
            },
            {
                data: (data: VejlederTableResponseModel) => data.sygehus,
            },
            {
                data: (data: VejlederTableResponseModel) => data.uddannelsessted,
            },
            {
                data: (data: VejlederTableResponseModel) => data,
                render: (data: VejlederTableResponseModel) => {
                    const startDate = data.startDatoUtc ? dateTimeStringFromApiToDate(data.startDatoUtc) : "";
                    const slutDate = data.slutDatoUtc ? dateTimeStringFromApiToDate(data.slutDatoUtc) : "";
                    const startDateString = startDate ? startDate.dateWithoutTimeFormat(false) : "";
                    const endDateString = slutDate ? slutDate.dateWithoutTimeFormat(false) : "";
                    const period = startDateString || endDateString ? `${startDateString} - ${endDateString}` : "";
                    return `${period}`;
                }
            }
        ]);

        return columns;
    };

    const afterRowCreation = (data: VejlederTableResponseModel) => {
        setclickedUddannelsesstedTilknytningId(data.vejlederTilknytningId);
    }

    const renderTable = () => {
        return (
            <TableAsync key={tableNumber + validatedDate.validatedDate}
                        tableIdentifier={"vejlederTable" + tableNumber}
                        tableHeader={Localizer.global_vejledere()}
                        renderTableHead={renderTableHead}
                        renderHeaderActions={
                            {
                                renderMethod: renderTableActions, actions: [
                                    new DataTableHeaderAction(showEndedTilknytningerSwitchButtonId, onSwitchClicked),
                                    new DataTableHeaderAction(validateButtonId, handleClickValidateVejledere),
                                    new DataTableHeaderAction(excelButtonId, handleClickDownloadExcel, isMobile),
                                    new DataTableHeaderAction(createVejlederButtonId, () => {}),
                                ]
                            }
                        }

                        asyncUrl={stamdataVejlederApi.vejlederDatalist()}
                        columns={vejlederTableColums()}
                        rowClickFunction={afterRowCreation}
                        showFilteredRecordsInfo
            />
        );
    }

    const renderEditModal = (vejlederTilknytningId: string) => {
        return <SlideIn
            id={modalEditTarget}
            title={Localizer.vejlederPage_editVejleder()}
            modalSize={ModalSize.md}
            actionText={""}
            actionOnCloseCallback={() => {
                setclickedUddannelsesstedTilknytningId(undefined)
            }}
            actionFinishedCallback={() => {
                setTableNumber(tableNumber + 1);
                setclickedUddannelsesstedTilknytningId(undefined)
            }}
            defaultOpen={true}
            bodyContent={<VejlederEdit
                modalId={modalEditTarget}
                vejlederTilknytningId={vejlederTilknytningId}
            />}
            footerContent={
                <VejlederEditFooter modalId={modalEditTarget} vejlederTilknytningId={vejlederTilknytningId} />
            }
        />
    }

    return (
        <>
            {(validatedDate.validatedDate || !currentUser.IsUddannelsesansvarlig()) &&
                renderTable()
            }

            {clickedUddannelsesstedTilknytningId &&
                renderEditModal(clickedUddannelsesstedTilknytningId)
            }

            <SlideIn
                key={tableNumber + 1}
                id={modalTarget}
                modalSize={ModalSize.md}
                title={Localizer.vejlederPage_createVejleder()}
                actionText={Localizer.vejlederPage_creatingVejleder()}
                actionFinishedCallback={() => {
                    setTableNumber(tableNumber + 1);
                    setclickedUddannelsesstedTilknytningId(undefined)
                }}
                bodyContent={<VejlederCrud modalId={modalTarget}/>}
                footerContent={<VejlederCrudFooter />}
            />
        </>
    );
}
